import { StateProps } from "./types";
export const initialState: StateProps = {
  loading: 0,
  error: undefined,
  data: {
    projectsList: undefined,
    project: undefined,
    projectMembers: undefined,
  },
};
