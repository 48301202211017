// React
import { User } from "firebase/auth";
import { useContext, createContext } from "react";
import { TransportFailure } from "../internals/transports/transported-data/transport-failures";

export type AuthSession = {
  user?: User;
  error?: TransportFailure;
  loading: boolean;
};

export type AuthContextProps = {
  session: AuthSession;
  setSession: (session: AuthSession) => void;
};

const AuthContextDefaults: AuthContextProps = {
  session: {
    user: undefined,
    error: undefined,
    loading: false,
  },
  setSession: () => {
    throw new Error("to use this context, please wrap your component with AuthContextProvider");
  },
};

// Context
export const AuthContext = createContext<AuthContextProps>(AuthContextDefaults);

// Hook
export const useAuthContext = (): AuthContextProps => useContext(AuthContext);
