import { z } from "zod";

export enum KnowledgeGraphStatus {
  CREATED = "created",
  FAILED = "failed",
  RUNNING = "running",
  DONE = "done",
}

export const KnowledgeGraphSchema = z.object({
  status: z.nativeEnum(KnowledgeGraphStatus),
  message: z.string().optional().nullable(),
  explanation: z.string().optional().nullable(),
});
