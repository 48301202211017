import { EnvironmentVariables } from "logic/internals/runtime/environment-variables";
import tinycolor from "tinycolor2";
import { useToken } from "./token";

export function useExtraToken() {
  const token = useToken();

  return {
    boxShadowLeft:
      "rgb(0 0 0 / 10%) -1px 0px 2px 0px, rgb(0 0 0 / 10%) -1px 0px 2px -1px, rgb(0 0 0 / 10%) -1px 0px 2px 0px",
    boxShadowWithColor: (color: string) =>
      `0 6px 16px 0 ${tinycolor(color).setAlpha(0.2).toRgbString()}, 0 3px 6px -4px ${tinycolor(
        color
      )
        .setAlpha(0.2)
        .toRgbString()}, 0 9px 28px 8px ${tinycolor(color).setAlpha(0.2).toRgbString()}`,
    colorPrimaryBgSecondary: "#F6F6FF",
    colorPrimaryBgFillSecondary: tinycolor(token.colorPrimary).setAlpha(0.1).toRgbString(),
    colorPrimary10Bg: "#242444",
    colorBgBaseHover: "#eeeeee",
    colorNavigationBackgroundColor: "#FFFFFF",
    colorBgCard: "#FFFFFF",
    colorSideBarBg: EnvironmentVariables.APP_ENV === "staging" ? "#e66465" : "#262626",
    colorTopBarSelected: "#ECECFD",
    topBarBgColor: "#3E3882",
    serifFont: "var(--font-2)",
  };
}
