import { WebSocketConnectionConfig } from "store/middleware/websockets/types";
import {
  handleSummaryMessage,
  handleRemainingBatchedMessages,
} from "./utils/handle-summary-message";
import { disconnectSummaryWS } from "./slice";
import { SummaryMessageSchema } from "./schemas";

export const onMessage: WebSocketConnectionConfig<typeof SummaryMessageSchema>["onMessage"] = (
  message
) => {
  handleSummaryMessage(message);
};

export const onClose: WebSocketConnectionConfig<typeof SummaryMessageSchema>["onClose"] = (
  store,
  resourceId
) => {
  if (store && resourceId) {
    store.dispatch(disconnectSummaryWS(resourceId));
    handleRemainingBatchedMessages();
  }
};

export const onInvalidMessageReceived: WebSocketConnectionConfig<
  typeof SummaryMessageSchema
>["onInvalidMessageReceived"] = (store, resource) => {
  if (resource) {
    store.dispatch(disconnectSummaryWS(resource));
  }
};

export const summariesWSConnectionConfig: WebSocketConnectionConfig<typeof SummaryMessageSchema> = {
  path: "/summaries/stream/",
  messageSchema: SummaryMessageSchema,
  onMessage: onMessage,
  onClose: onClose,
  onInvalidMessageReceived: onInvalidMessageReceived,
};
