export const stripePriceIds = {
  /***** ----- Legacy Plans ----- *****/
  // Test Basic
  testBasic20: ["price_1OHQG2BlQAbtJTlPDKlR7jd7", "price_1OHQG2BlQAbtJTlPPONeGUhy"],
  testBasic100: ["price_1OHQK7BlQAbtJTlPPjp4I4pu", "price_1OHQK7BlQAbtJTlP8AMAnxI5"],
  // Test Business
  testBusiness500: ["price_1OHQUABlQAbtJTlPQxZMy6xf", "price_1OEZswBlQAbtJTlPklRO21kB"],
  testBusiness1000: ["price_1OEZVVBlQAbtJTlPptzXmtB8", "price_1OEZWHBlQAbtJTlPRGXDow9u"],
  // Prod Basic
  prodBasic20: ["price_1OMqGoBlQAbtJTlP5FmH8QPZ", "price_1OMqGoBlQAbtJTlPIBSP9gra"],
  prodBasic100: ["price_1OMqYlBlQAbtJTlPIncRNtQw", "price_1OMqYlBlQAbtJTlPZ4ynVdHd"],
  // Prod Business
  prodBusiness500: ["price_1OMqc3BlQAbtJTlP1s6SANci", "price_1OMqc3BlQAbtJTlPyl03ijqz"],
  prodBusiness1000: ["price_1OMqeJBlQAbtJTlP0LtWMNWT", "price_1OMqeJBlQAbtJTlPduqgeQ7k"],

  /***** ----- New Plans ----- *****/
  // Test In-Depth Interviews Annual
  testInDepthInterviews500: ["price_1PzhYTBlQAbtJTlPQ3tf6gbb"],
  testInDepthInterviews1000: ["price_1PzhaDBlQAbtJTlPnRe1g8Ov"],
  testInDepthInterviews2000: ["price_1Q16xFBlQAbtJTlP40rUpDfp"],
  testInDepthInterviews5000: ["price_1Pzhb4BlQAbtJTlPPGSSOZfT"],
  // Test In-Depth Interviews Monthly
  testInDepthInterviews42: ["price_1QA8DnBlQAbtJTlPaqZJM4XP"],
  testInDepthInterviews83: ["price_1QA8EEBlQAbtJTlPA4Gogvlj"],
  testInDepthInterviews167: ["price_1QA8F2BlQAbtJTlPVAg0U6Nv"],
  testInDepthInterviews417: ["price_1QA8FTBlQAbtJTlP9HxmbLAN"],
  // Test Interviews RAG
  testInterviewsRag500: ["price_1Q6D2WBlQAbtJTlPju4WUaqh"],
  testInterviewsRag1000: ["price_1Q6D4PBlQAbtJTlPc21mm84Z"],
  testInterviewsRag2000: ["price_1Q6D5OBlQAbtJTlPWi0Lyxhs"],
  testInterviewsRag5000: ["price_1Q6D5lBlQAbtJTlPd8fiebE3"],
  // Test Surveys
  testSurveys200: ["price_1PzhVDBlQAbtJTlP9rRA209m"],
  testSurveys500: ["price_1PzhSJBlQAbtJTlPyrR4XKck"],
  testSurveys1200: ["price_1Q16y9BlQAbtJTlPAO6cRX9P"],
  testSurveys6000: ["price_1PzhX2BlQAbtJTlPGu4WCmjG"],
  testSurveys12000: ["price_1PzhXPBlQAbtJTlP8pfT8OfB"],
  // Prod In-Depth Interviews
  prodInDepthInterviews500: ["price_1Q3affBlQAbtJTlP0j5q0kV2"],
  prodInDepthInterviews1000: ["price_1Q3affBlQAbtJTlPobiMY4AM"],
  prodInDepthInterviews2000: ["price_1Q3affBlQAbtJTlPfAxpeDSB"],
  prodInDepthInterviews5000: ["price_1Q3affBlQAbtJTlPe7OPobcD"],
  // Prod In-Depth Interviews Monthly
  prodInDepthInterviews42: ["price_1QAAoGBlQAbtJTlPC7rqRar9"],
  prodInDepthInterviews83: ["price_1QAApRBlQAbtJTlPkWoHlEmE"],
  prodInDepthInterviews167: ["price_1QAAqeBlQAbtJTlPJkgeHbMi"],
  prodInDepthInterviews417: ["price_1QAArqBlQAbtJTlPz1elsKg2"],
  // Prod Interviews RAG
  prodInterviewsRag500: ["price_1Q7CMEBlQAbtJTlPWOXSfqF6"],
  prodInterviewsRag1000: ["price_1Q7CO7BlQAbtJTlPdnalwnF1"],
  prodInterviewsRag2000: ["price_1Q7COTBlQAbtJTlPN5ZDBOB2"],
  prodInterviewsRag5000: ["price_1Q7COlBlQAbtJTlPu5x3Uupx"],
  // Prod Surveys
  prodSurveys200: [],
  prodSurveys500: [],
  prodSurveys1200: [],
  prodSurveys6000: [],
  prodSurveys12000: [],
};
