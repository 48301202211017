import { Auth, getAuth } from "firebase/auth";
import { EnvironmentVariables } from "../../runtime/environment-variables";
import { getFirebaseApp } from "./firebase-app";

let firebaseAuth: Auth | undefined = undefined;

export const getFirebaseAuth = () => {
  if (!firebaseAuth) {
    const firebaseApp = getFirebaseApp();
    firebaseAuth = getAuth(firebaseApp);

    // if (process.env.NODE_ENV === 'development') {
    //   connectAuthEmulator(
    //     firebaseAuth,
    //     'http://localhost:9099',
    //     {
    //       disableWarnings: true,
    //     },
    //   );
    // }

    if (EnvironmentVariables.DEBUG_FEATURES) {
      window.firebaseAuth = firebaseAuth;
    }
  }

  return firebaseAuth;
};
