import { Middleware } from "@reduxjs/toolkit";
import { Logger } from "logic/internals/logging/logger";
import { EnvironmentVariables } from "logic/internals/runtime/environment-variables";
import {
  connectSummaryConversationsWS,
  disconnectSummaryConversationsWS,
} from "store/modules/summaries-conversations/slice";
import { connectSummaryWS, disconnectSummaryWS } from "store/modules/summaries/slice";
import {
  connectUserInterviewConversationsWS,
  disconnectUserInterviewConversationsWS,
} from "store/modules/user-interview-conversations/slice";
import { isConnectWebSocketAction, removeKeyId } from "./typeguards";
import { WebSocketConnectionsConfig } from "./types";
import {
  connectUserInterviewWS,
  disconnectUserInterviewsWS,
} from "@/store/modules/user-interviews/slice";

export const webSocketMiddleware = (connectionsConfig: WebSocketConnectionsConfig): Middleware => {
  const sockets: { [key: string]: WebSocket } = {};
  let closedByClient: string[] = [];

  return (store) => (next) => (action) => {
    if (!isConnectWebSocketAction(action)) {
      return next(action);
    }

    const { key } = action.payload;

    const configKey = removeKeyId(key);

    const config = connectionsConfig[configKey];

    if (!config) {
      return next(action);
    }

    /* ONMESSAGE HANDLER */
    const onMessage = (event: MessageEvent) => {
      let unknownMessage: unknown;

      try {
        unknownMessage = JSON.parse(event.data as string) as unknown;
      } catch (err) {
        Logger.logError("use-main-api-web-socket-adapter:receive-message:invalid-json", err);

        return;
      }

      const messageValidation = config.messageSchema.safeParse(unknownMessage);

      if (!messageValidation.success) {
        Logger.logError(
          "use-main-api-web-socket-adapter:receive-message:invalid-data",
          messageValidation.error
        );

        config.onInvalidMessageReceived(store, action.payload.resourceId);

        return;
      }

      config.onMessage(messageValidation.data, store, action.payload.resourceId);
    };

    const onError = () => {
      const socket = sockets[key];
      socket && removeListeners(socket);

      Logger.logError("use-main-api-web-socket-adapter:error-listener", new Error());
      config.onClose(store, action.payload.resourceId, { by: "error" });
    };

    const onClose = () => {
      const socket = sockets[key];
      socket && removeListeners(socket);

      const wasClosedByClient = closedByClient.includes(action.payload.resourceId);

      config.onClose(store, action.payload.resourceId, {
        by: wasClosedByClient ? "client" : "server",
      });

      if (wasClosedByClient) {
        closedByClient = closedByClient.filter((id) => id !== action.payload.resourceId);
      }
    };

    const removeListeners = (socket: WebSocket) => {
      socket.removeEventListener("message", onMessage);
      socket.removeEventListener("error", onError);
      socket.removeEventListener("close", onClose);
    };

    switch (action.type) {
      case connectUserInterviewWS.type:
      case connectSummaryWS.type:
      case connectUserInterviewConversationsWS.type:
      case connectSummaryConversationsWS.type:
        if (!sockets[key]) {
          return (async () => {
            const { resourceId, path } = action.payload;
            let queryParams = "";
            let retryCount = 0;
            const maxRetries = config.maxRetries ?? 0;

            if (action.payload.queryParams) {
              queryParams = `?${action.payload.queryParams}`;
            }

            const url = `${EnvironmentVariables.MAIN_API_SOCKET_URL}${path || config.path}${resourceId}${queryParams}`;
            while (retryCount <= maxRetries) {
              try {
                const socket = new WebSocket(url);

                /* ONMESSAGE LISTENER */
                socket.addEventListener("message", onMessage);

                /* ONERROR LISTENER */
                socket.addEventListener("error", onError);

                /* ONCLOSE LISTENER */
                socket.addEventListener("close", onClose);

                await new Promise((resolve, reject) => {
                  /* SUCCESSFUL CONNECTION */
                  const openListener = () => {
                    socket.removeEventListener("open", openListener);

                    resolve(undefined);
                  };
                  socket.addEventListener("open", openListener);

                  /* FAILED CONNECTION */
                  const errorOnStartListener = () => {
                    Logger.logError("use-main-api-web-socket-adapter:error-listener", new Error());

                    socket.removeEventListener("error", errorOnStartListener);
                    config.onClose(store, resourceId, { by: "error" });

                    // reject to trigger the retry logic
                    reject(new Error("Connection failed"));
                  };
                  socket.addEventListener("error", errorOnStartListener);
                });

                sockets[key] = socket; // Store the socket instance
                break;
              } catch (error) {
                if (retryCount === maxRetries) {
                  Logger.logError(
                    "use-main-api-web-socket-adapter:connection-failed",
                    error as Error,
                    { maxRetriesReached: maxRetries > 0 }
                  );
                  config.onClose(store, resourceId, { by: "error" });
                  break;
                }

                Logger.logError(
                  "use-main-api-web-socket-adapter:connection-retry",
                  error as Error,
                  { attempt: retryCount + 1, maxRetries }
                );

                // Wait before retrying
                await new Promise((resolve) => setTimeout(resolve, 2000));
                retryCount++;
              }
            }
          })();
        }
        break;
      case disconnectUserInterviewsWS.type:
      case disconnectSummaryWS.type:
      case disconnectUserInterviewConversationsWS.type:
      case disconnectSummaryConversationsWS.type:
        {
          const socket = sockets[key];
          if (socket) {
            if (action.payload.wasClosed?.by === "client") {
              closedByClient = [...closedByClient, action.payload.resourceId];
            }
            socket.close();
            delete sockets[key];
          }
        }
        break;
    }

    // Always call next(action) so actions are not swallowed
    return next(action);
  };
};
