import { store } from "@/store";

import { streamSummary } from "../slice";
import { SummaryMessage } from "../types";

const BATCH_SIZE = 5;

let batchedMessages: SummaryMessage[] = [];

export const handleSummaryMessage = (newMessage: SummaryMessage) => {
  const currMessages: SummaryMessage[] = [...batchedMessages, newMessage];

  // Check if we have reached the batch size
  if (currMessages.length >= BATCH_SIZE) {
    // Dispatch the batched messages
    store.dispatch(streamSummary({ messages: currMessages }));

    // Clear the batched messages array
    batchedMessages = [];
  } else {
    batchedMessages = currMessages;
  }
};

export const handleRemainingBatchedMessages = () => {
  if (batchedMessages.length) {
    // Dispatch the remaining batched messages
    store.dispatch(streamSummary({ messages: batchedMessages }));
    batchedMessages = [];
  }
};
