import { StateProps } from "./types";
export const initialState: StateProps = {
  loading: 0,
  error: undefined,
  data: {
    workspacesList: undefined,
    workspace: undefined,
    workspaceMembers: undefined,
    workspaceMode: undefined,
  },
};
