import * as Sentry from "@sentry/nextjs";
import posthog from "posthog-js";

export const initializeSentry = () => {
  const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
  const SENTRY_ENV = process.env.NEXT_PUBLIC_SENTRY_ENV;
  const SENTRY_ORGANIZATION = process.env.NEXT_PUBLIC_SENTRY_ORGANIZATION;
  const SENTRY_PROJECT_ID = process.env.NEXT_PUBLIC_SENTRY_PROJECT_ID;

  if (SENTRY_DSN) {
    if (!SENTRY_ENV) {
      throw new Error();
    }

    Sentry.init({
      dsn: SENTRY_DSN,
      // Adjust this value in production, or use tracesSampler for greater control
      tracesSampleRate: 1.0,
      // ...
      // Note: if you want to override the automatic release value, do not set a
      // `release` value here - use the environment variable `SENTRY_RELEASE`, so
      // that it will also get attached to your source maps
      environment: SENTRY_ENV,
      integrations: [
        new posthog.SentryIntegration(posthog, SENTRY_ORGANIZATION, SENTRY_PROJECT_ID),
      ],
    });
  }
};
