import { ConnectWebSocketAction, WebSocketConnectionConfigKeys } from "./types";

const hasType = (action: unknown): action is { type: string } => {
  return (
    typeof action === "object" &&
    action !== null &&
    "type" in action &&
    action.type !== null &&
    typeof action.type === "string"
  );
};

const hasWSConnectionKey = (
  action: unknown
): action is { payload: { key: WebSocketConnectionConfigKeys } } => {
  return (
    typeof action === "object" &&
    action !== null &&
    "payload" in action &&
    action.payload !== null &&
    typeof action.payload === "object" &&
    "key" in action.payload &&
    typeof action.payload.key === "string" &&
    Object.values(WebSocketConnectionConfigKeys).includes(removeKeyId(action.payload.key))
  );
};

const hasPayloadResourceId = (action: unknown): action is { payload: { resourceId: string } } => {
  return (
    typeof action === "object" &&
    action !== null &&
    "payload" in action &&
    action.payload !== null &&
    typeof action.payload === "object" &&
    "resourceId" in action.payload &&
    typeof action.payload.resourceId === "string"
  );
};

export const isConnectWebSocketAction = (action: unknown): action is ConnectWebSocketAction => {
  return hasType(action) && hasWSConnectionKey(action) && hasPayloadResourceId(action);
};

export const removeKeyId = (key: string): WebSocketConnectionConfigKeys =>
  key.split("-")[0] as WebSocketConnectionConfigKeys;
