import { z } from "zod";
import { GeneratedSyntheticUserSchema } from "../synthetic-users/schemas";

export enum SurveyStatus {
  CREATED = "created",
  GENERATING = "generating",
  SURVEYING = "surveying",
  FINISHED = "finished",
  FAILED = "failed",
}

const SurveyAudienceSchema = z.object({
  id: z.string(),
  description: z.string(),
  projectId: z.string(),
  deleted: z.boolean(),
  createdAt: z.string(),
});

const SurveyAnswerSchema = z.object({
  generated_synthetic_user_id: z.string(),
  answers: z.any(),
});

const SurveySUSchema = z.object({
  synthetic_user: SurveyAudienceSchema,
  quantity: z.number(),
  generated_synthetic_users: z.array(GeneratedSyntheticUserSchema),
  answers: z.array(SurveyAnswerSchema),
});

const SurveyDataSchema = z.object({
  elements: z
    .array(
      z.object({
        name: z.string(),
        title: z.string().optional().nullable(),
        type: z.string(),
        choices: z
          .array(
            z.union([
              z.string(),
              z.object({
                text: z.string(),
                value: z.string(),
              }),
            ])
          )
          .optional()
          .nullable(),
      })
    )
    .optional(),
});

export const SurveySchema = z.object({
  id: z.string(),
  description: z.string().optional().nullable(),
  name: z.string(),
  projectId: z.string().optional().nullable(),
  createdBy: z.string().optional().nullable(),
  createdAt: z.string(),
  audiences: z.array(SurveySUSchema),
  status: z.nativeEnum(SurveyStatus),
  data: SurveyDataSchema.optional().nullable(),
});
