import { Button, Result } from "antd";
import AntIcon from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

export function FatalError() {
  return (
    <>
      <Result
        status={"error"}
        title={"An internal error occured."}
        subTitle={"Please refresh the page or click below to go back to the home page."}
        icon={
          <AntIcon
            component={() => (
              <FontAwesomeIcon className={`icon-thumbnail mb-4`} icon={faExclamationCircle} />
            )}
          />
        }
      />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button type="primary" href="/">
          Go back to home page
        </Button>
      </div>
    </>
  );
}
