// Redux
import { createReducer, isAnyOf } from "@reduxjs/toolkit";
// Types
import { StateProps } from "./types";
// State
import { initialState } from "./initial-state";
// Actions
import {
  getSurveyById,
  startSurvey,
  getProjectSurveys,
  createSurvey,
  updateSurveyData,
  updateSurveyAudiences,
  resetSurveys,
  cloneSurvey,
  pollSurveyAudiences,
  resetGeneratingSurvey,
  generatingSurvey,
} from "./actions";

export const reducer = createReducer<StateProps>(initialState, (builder) => {
  builder.addCase(resetSurveys, () => initialState);
  builder.addCase(resetGeneratingSurvey, (state) => ({
    ...state,
    generatingSurvey: false,
  }));
  builder.addCase(generatingSurvey, (state) => ({
    ...state,
    generatingSurvey: true,
  }));
  builder
    // Loading start
    .addMatcher(
      isAnyOf(
        getSurveyById.pending,
        startSurvey.pending,
        getProjectSurveys.pending,
        createSurvey.pending,
        updateSurveyData.pending,
        updateSurveyAudiences.pending,
        cloneSurvey.pending
      ),
      (state) => ({
        ...state,
        loading: true,
      })
    )
    // Get Study fulfilled
    .addMatcher(
      isAnyOf(
        getSurveyById.fulfilled,
        startSurvey.fulfilled,
        getProjectSurveys.fulfilled,
        createSurvey.fulfilled,
        updateSurveyData.fulfilled,
        updateSurveyAudiences.fulfilled,
        cloneSurvey.fulfilled,
        pollSurveyAudiences.fulfilled
      ),
      (state, action) => ({
        ...state,
        ...action.payload,
      })
    )
    // Loading end
    .addMatcher(
      isAnyOf(
        getSurveyById.fulfilled,
        getSurveyById.rejected,
        startSurvey.fulfilled,
        startSurvey.rejected,
        getProjectSurveys.fulfilled,
        getProjectSurveys.rejected,
        createSurvey.fulfilled,
        createSurvey.rejected,
        updateSurveyData.fulfilled,
        updateSurveyData.rejected,
        updateSurveyAudiences.fulfilled,
        updateSurveyAudiences.rejected,
        cloneSurvey.fulfilled,
        cloneSurvey.rejected
      ),
      (state) => ({
        ...state,
        loading: false,
      })
    );
});
