import { GeneratedSyntheticUser } from "store/modules/synthetic-users/types";
import { UserInterviewMessage } from "../types";

export const processUserInterviewMessage = (
  message: UserInterviewMessage,
  prevUserInterview: string[],
  currentQuestionIndex: number
) => {
  let personalInformation: GeneratedSyntheticUser | null = null;
  let summedUserInterviewText = [...prevUserInterview];
  let summedCurrentQuestion = prevUserInterview[currentQuestionIndex] || "";

  if (typeof message.message === "string") {
    // handle the beginning of a question
    if (
      message.type === "beginning_of_question" &&
      (message.section === "human" || message.section === "researcher")
    ) {
      summedCurrentQuestion = "";

      // handle the end of a question
    } else if (
      message.type === "end_of_question" &&
      message.section !== "human" &&
      message.section !== "researcher"
    ) {
      summedCurrentQuestion = "";
      summedUserInterviewText = [...summedUserInterviewText, summedCurrentQuestion];
    } else {
      // handle the stream of a question
      if (
        (message.section === "human" || message.section === "researcher") &&
        message.type === "stream"
      ) {
        // If the message coming from the researcher of human has more than one line,
        // we need to add the ## markdown before the message
        // ## ${questionNumber} should be only on the first line
        const interviewMessage = message.message
          .split("\n")
          .map((line, index) =>
            index === 0 ? `## ${currentQuestionIndex + 1} - ${line}` : `## ${line}`
          )
          .join("\n");
        summedCurrentQuestion = `${summedCurrentQuestion} ${interviewMessage} \n\n`;
      } else {
        summedCurrentQuestion = `${summedCurrentQuestion}${message.message}`;
      }

      // update the last question in the array
      summedUserInterviewText[currentQuestionIndex] = summedCurrentQuestion;
    }
  } else {
    // handle personal information
    if (message.type === "personal_information") {
      personalInformation = message.message;
    }
  }

  return { personalInformation, summedUserInterviewText };
};
