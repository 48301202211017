// Redux Toolkit
import { createApi } from "@reduxjs/toolkit/query/react";
import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
// Store utils
import { RootState } from "store";
import { handleChangeProject } from "store/utils/side-effects/handle-change-project";
import { handleAddProject } from "store/utils/side-effects/handle-add-project";
import { customBaseQuery } from "store/utils/custom-base-query";
import { parseError } from "store/utils/parse-error";
// Types
import { Project, ProjectMember } from "./types";
// Schemas
import { ProjectMemberSchema, ProjectSchema } from "./schemas";
// Initial state
import { initialState } from "./initial-state";
// Transport failures
import { TransportFailure } from "logic/internals/transports/transported-data/transport-failures";
// Zod
import { z } from "zod";

// Create the API slice
export const projectsApi = createApi({
  reducerPath: "projectsApi",
  baseQuery: customBaseQuery,
  tagTypes: ["ProjectMembers"],
  endpoints: (builder) => ({
    /***** --- Get Project By Id Query --- *****/
    getProjectById: builder.query<Project, { projectId: string }>({
      query: ({ projectId }) => ({
        type: "fetch",
        url: `/projects/project/${projectId}`,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        const result = await queryFulfilled;
        const project = result.data;

        // Side effects
        handleChangeProject(project);
      },
      extraOptions: {
        dataSchema: ProjectSchema,
      },
    }),
    /***** --- Add Project Mutation --- *****/
    addProject: builder.mutation<
      Project,
      {
        name: string;
        description?: string;
        workspaceId: string;
      }
    >({
      query: ({ name, description, workspaceId }) => ({
        type: "fetch",
        url: `/projects`,
        method: "POST",
        body: { name, description, workspaceId },
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        await queryFulfilled;
        // Side effects
        handleAddProject();
      },
      extraOptions: {
        dataSchema: ProjectSchema,
      },
    }),
    /***** --- Update Project Description Mutation --- *****/
    updateProjectDescription: builder.mutation<
      Project,
      {
        projectId: string;
        name: string;
        description?: string;
        workspaceId: string;
      }
    >({
      query: ({ projectId, name, description, workspaceId }) => ({
        type: "fetch",
        url: `/projects/project/${projectId}/description`,
        method: "PUT",
        body: { description, name, workspaceId },
      }),
      extraOptions: {
        dataSchema: ProjectSchema,
      },
    }),
    /***** --- Delete Project Mutation --- *****/
    deleteProject: builder.mutation<
      void,
      {
        projectId: string;
      }
    >({
      query: ({ projectId }) => ({
        type: "fetch",
        url: `/projects/project/${projectId}`,
        method: "DELETE",
      }),
      async onQueryStarted({ projectId }, { queryFulfilled, dispatch, getState }) {
        await queryFulfilled;

        const projectsList = (getState() as RootState).projects.data.projectsList;
        const remainingProjects = projectsList?.filter((project) => project.id !== projectId);
        const projectToLoad = remainingProjects && remainingProjects[0];

        // Side effects
        if (projectToLoad) {
          dispatch(
            projectsApi.endpoints.getProjectById.initiate(
              { projectId: projectToLoad.id },
              { forceRefetch: true }
            )
          );
        }
      },
    }),
    /***** --- Get Project Members Query --- *****/
    getProjectMembers: builder.query<ProjectMember[], { projectId: string }>({
      query: ({ projectId }) => ({
        type: "fetch",
        url: `/projects/project/${projectId}/users`,
      }),
      providesTags: (result, error, { projectId }) => [{ type: "ProjectMembers", id: projectId }],
      extraOptions: {
        dataSchema: z.array(ProjectMemberSchema),
      },
    }),
    /***** --- Add Project Member Mutation --- *****/
    addProjectMember: builder.mutation<
      ProjectMember[] | { detail: string },
      {
        projectId: string;
        userId: string;
      }
    >({
      query: ({ projectId, userId }) => ({
        type: "fetch",
        url: `/projects/project/${projectId}/users/${userId}`,
        method: "POST",
      }),
      extraOptions: {
        dataSchema: z.union([z.array(ProjectMemberSchema), z.object({ detail: z.string() })]),
      },
    }),
    /***** --- Invite Project Member Mutation --- *****/
    inviteProjectMember: builder.mutation<
      Project | { detail: string },
      {
        projectId: string;
        email: string;
      }
    >({
      query: ({ projectId, email }) => ({
        type: "fetch",
        url: `/projectInvites/invite`,
        method: "POST",
        body: { projectId, email },
      }),
      invalidatesTags: (result, error, { projectId }) => [
        { type: "ProjectMembers", id: projectId },
      ],
      extraOptions: {
        dataSchema: z.union([z.array(ProjectSchema), z.object({ detail: z.string() })]),
      },
    }),
    /***** --- Remove Project Member Mutation --- *****/
    removeProjectMember: builder.mutation<
      ProjectMember[],
      {
        projectId: string;
        userId: string;
      }
    >({
      query: ({ projectId, userId }) => ({
        type: "fetch",
        url: `/projects/project/${projectId}/users/${userId}`,
        method: "DELETE",
      }),
      extraOptions: {
        dataSchema: z.array(ProjectMemberSchema),
      },
    }),
    /***** --- Remove Project Invite Mutation --- *****/
    removeProjectInvite: builder.mutation<
      ProjectMember[],
      {
        projectInviteId: string;
      }
    >({
      query: ({ projectInviteId }) => ({
        type: "fetch",
        url: `/projectInvites/${projectInviteId}`,
        method: "DELETE",
      }),
      extraOptions: {
        dataSchema: z.array(ProjectMemberSchema),
      },
    }),
    /***** --- Grant Project Member Admin Status Mutation --- *****/
    grantProjectMemberAdminStatus: builder.mutation<
      ProjectMember[],
      {
        projectId: string;
        userId: string;
      }
    >({
      query: ({ projectId, userId }) => ({
        type: "fetch",
        url: `/projects/project/${projectId}/users/${userId}/admin`,
        method: "POST",
      }),
      extraOptions: {
        dataSchema: z.array(ProjectMemberSchema),
      },
    }),
    /***** --- Revoke Project Member Admin Status Mutation --- *****/
    revokeProjectMemberAdminStatus: builder.mutation<
      ProjectMember[],
      {
        projectId: string;
        userId: string;
      }
    >({
      query: ({ projectId, userId }) => ({
        type: "fetch",
        url: `/projects/project/${projectId}/users/${userId}/revoke-admin`,
        method: "POST",
      }),
      extraOptions: {
        dataSchema: z.array(ProjectMemberSchema),
      },
    }),
  }),
});

// Create the regular slice
export const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    /***** --- Reset Project --- *****/
    resetProject: () => initialState,
    /***** --- Set Workspace Projects --- *****/
    setWorkspaceProjects: (
      state,
      action: PayloadAction<{
        project?: Project;
        projectsList?: Project[];
        error?: TransportFailure;
      }>
    ) => {
      state.data.project = action.payload.project;
      state.data.projectsList = action.payload.projectsList;
      state.error = action.payload.error;
    },
  },
  extraReducers: (builder) => {
    builder
      /***** --- Handle Loading --- *****/
      .addMatcher(
        isAnyOf(
          projectsApi.endpoints.getProjectById.matchPending,
          projectsApi.endpoints.addProject.matchPending,
          projectsApi.endpoints.updateProjectDescription.matchPending,
          projectsApi.endpoints.deleteProject.matchPending,
          projectsApi.endpoints.getProjectMembers.matchPending,
          projectsApi.endpoints.addProjectMember.matchPending,
          projectsApi.endpoints.inviteProjectMember.matchPending,
          projectsApi.endpoints.removeProjectMember.matchPending,
          projectsApi.endpoints.removeProjectInvite.matchPending,
          projectsApi.endpoints.grantProjectMemberAdminStatus.matchPending,
          projectsApi.endpoints.revokeProjectMemberAdminStatus.matchPending
        ),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          projectsApi.endpoints.getProjectById.matchFulfilled,
          projectsApi.endpoints.getProjectById.matchRejected,
          projectsApi.endpoints.updateProjectDescription.matchFulfilled,
          projectsApi.endpoints.addProject.matchRejected,
          projectsApi.endpoints.addProject.matchRejected,
          projectsApi.endpoints.updateProjectDescription.matchRejected,
          projectsApi.endpoints.deleteProject.matchFulfilled,
          projectsApi.endpoints.deleteProject.matchRejected,
          projectsApi.endpoints.getProjectMembers.matchFulfilled,
          projectsApi.endpoints.getProjectMembers.matchRejected,
          projectsApi.endpoints.addProjectMember.matchFulfilled,
          projectsApi.endpoints.addProjectMember.matchRejected,
          projectsApi.endpoints.removeProjectMember.matchFulfilled,
          projectsApi.endpoints.removeProjectMember.matchRejected,
          projectsApi.endpoints.inviteProjectMember.matchFulfilled,
          projectsApi.endpoints.inviteProjectMember.matchRejected,
          projectsApi.endpoints.removeProjectInvite.matchFulfilled,
          projectsApi.endpoints.removeProjectInvite.matchRejected,
          projectsApi.endpoints.grantProjectMemberAdminStatus.matchFulfilled,
          projectsApi.endpoints.grantProjectMemberAdminStatus.matchRejected,
          projectsApi.endpoints.revokeProjectMemberAdminStatus.matchFulfilled,
          projectsApi.endpoints.revokeProjectMemberAdminStatus.matchRejected
        ),
        (state) => {
          state.loading = false;
        }
      )
      /***** --- Handle Get Project By Id Fulfilled --- *****/
      .addMatcher(projectsApi.endpoints.getProjectById.matchFulfilled, (state, action) => {
        state.data.project = action.payload;
      })
      /***** --- Handle Add Project Fulfilled --- *****/
      .addMatcher(projectsApi.endpoints.addProject.matchFulfilled, (state, action) => {
        const newProject = action.payload;
        state.data.project = newProject;
        state.data.projectsList = [newProject, ...(state.data.projectsList || [])];
      })
      /***** --- Handle Update Project Description Fulfilled --- *****/
      .addMatcher(
        projectsApi.endpoints.updateProjectDescription.matchFulfilled,
        (state, action) => {
          const updatedProject = action.payload;
          state.data.project = updatedProject;
          state.data.projectsList = state.data.projectsList?.map((project) =>
            project.id === updatedProject.id ? updatedProject : project
          );
        }
      )
      /***** --- Handle Delete Project Fulfilled --- *****/
      .addMatcher(projectsApi.endpoints.deleteProject.matchFulfilled, (state, action) => {
        const deletedProjectId = action.meta?.arg?.originalArgs?.projectId;
        state.data.projectsList = state.data.projectsList?.filter(
          (project) => project.id !== deletedProjectId
        );
      })
      /***** --- Handle Project Members Fulfilled --- *****/
      .addMatcher(
        isAnyOf(
          projectsApi.endpoints.getProjectMembers.matchFulfilled,
          projectsApi.endpoints.removeProjectMember.matchFulfilled,
          projectsApi.endpoints.removeProjectInvite.matchFulfilled,
          projectsApi.endpoints.grantProjectMemberAdminStatus.matchFulfilled,
          projectsApi.endpoints.revokeProjectMemberAdminStatus.matchFulfilled
        ),
        (state, action) => {
          state.data.projectMembers = action.payload;
        }
      )
      /***** --- Handle Add Project Member Fulfilled --- *****/
      .addMatcher(projectsApi.endpoints.addProjectMember.matchFulfilled, (state, action) => {
        if (Array.isArray(action.payload)) {
          state.data.projectMembers = action.payload;
        }
      })
      /***** --- Handle Errors --- *****/
      .addMatcher(
        isAnyOf(
          projectsApi.endpoints.getProjectById.matchRejected,
          projectsApi.endpoints.addProject.matchRejected,
          projectsApi.endpoints.updateProjectDescription.matchRejected,
          projectsApi.endpoints.deleteProject.matchRejected,
          projectsApi.endpoints.getProjectMembers.matchRejected,
          projectsApi.endpoints.addProjectMember.matchRejected,
          projectsApi.endpoints.inviteProjectMember.matchRejected,
          projectsApi.endpoints.removeProjectMember.matchRejected,
          projectsApi.endpoints.removeProjectInvite.matchRejected,
          projectsApi.endpoints.grantProjectMemberAdminStatus.matchRejected,
          projectsApi.endpoints.revokeProjectMemberAdminStatus.matchRejected
        ),
        (state, action) => {
          const error = parseError(action.error);
          state.error = error;
        }
      );
  },
});

// Export actions
export const { setWorkspaceProjects, resetProject } = projectsSlice.actions;

// Export hooks
export const {
  useGetProjectByIdQuery,
  useLazyGetProjectByIdQuery,
  useAddProjectMutation,
  useUpdateProjectDescriptionMutation,
  useDeleteProjectMutation,
  useGetProjectMembersQuery,
  useAddProjectMemberMutation,
  useInviteProjectMemberMutation,
  useRemoveProjectMemberMutation,
  useRemoveProjectInviteMutation,
  useGrantProjectMemberAdminStatusMutation,
  useRevokeProjectMemberAdminStatusMutation,
} = projectsApi;

// Combine the reducers
export const projectsReducer = {
  [projectsApi.reducerPath]: projectsApi.reducer,
  projects: projectsSlice.reducer,
};
