import { store } from "../..";
import { resetAudiences } from "../../modules/audiences/slice";
import { resetCustomScript } from "../../modules/custom-script/slice";
import { resetHistory } from "../../modules/history/slice";
import { knowledgeGraphApi } from "../../modules/knowledge-graph/slice";
import { resetProblems } from "../../modules/problems/slice";
import { resetRag } from "../../modules/rag/slice";
import { resetResearchAssistant } from "../../modules/research-assistant/slice";
import { resetResearchGoal } from "../../modules/research-goal/slice";
import { resetSolutions } from "../../modules/solutions/slice";
import { resetStudy } from "../../modules/study/slice";
import { resetSummaryConversations } from "../../modules/summaries-conversations/slice";
import { resetSummary } from "../../modules/summaries/slice";
import { resetSurveys } from "../../modules/surveys/actions";
import { resetSyntheticUsers } from "../../modules/synthetic-users/slice";
import { resetUserInterviewConversations } from "../../modules/user-interview-conversations/slice";
import { resetUserInterviews } from "../../modules/user-interviews/slice";

export const handleAddProject = () => {
  store.dispatch(resetAudiences());
  store.dispatch(resetProblems());
  store.dispatch(resetSolutions());
  store.dispatch(resetResearchAssistant());
  store.dispatch(resetCustomScript());
  store.dispatch(resetResearchGoal());
  store.dispatch(resetStudy());
  store.dispatch(resetSyntheticUsers());
  store.dispatch(resetUserInterviews());
  store.dispatch(resetUserInterviewConversations());
  store.dispatch(resetSummary());
  store.dispatch(resetSummaryConversations());
  store.dispatch(knowledgeGraphApi.util.invalidateTags(["KnowledgeGraph"]));
  store.dispatch(resetHistory());
  store.dispatch(resetRag());
  store.dispatch(resetSurveys());
};
