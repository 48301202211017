import _AntIcon from "@ant-design/icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSProperties, ReactElement, useMemo } from "react";

export function AntIcon(
  props: { style?: CSSProperties } & (
    | { component: () => ReactElement; icon?: undefined }
    | { component?: undefined; icon: IconProp }
  )
) {
  const iconComponent = useMemo(
    () => props.component || (() => <FontAwesomeIcon icon={props.icon} />),
    [props.component, props.icon]
  );
  return <_AntIcon style={props.style} component={iconComponent} />;
}
