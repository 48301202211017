import { z } from "zod";

export const GeneratedSyntheticUserSchema = z.object({
  id: z.string(),
  syntheticUserId: z.string(),
  syntheticUserDescription: z.string(),
  personalInformation: z.record(z.string()),
  personalityTraits: z.record(z.string()),
  miscellaneous: z.record(z.string()),
});
