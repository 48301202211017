// Redux Toolkit
import { createApi } from "@reduxjs/toolkit/query/react";
import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
// Store utils
import { customBaseQuery } from "store/utils/custom-base-query";
import { parseError } from "store/utils/parse-error";
// Types
import { Audience } from "./types";
// Schemas
import { AudiencesSchema } from "./schemas";
// Initial state
import { initialState } from "./initial-state";
// Transport failures
import { TransportFailure } from "logic/internals/transports/transported-data/transport-failures";
import { sdkApis } from "@/store/api-sdk";

// Create the API slice
export const audiencesApi = createApi({
  reducerPath: "audiencesApi",
  baseQuery: customBaseQuery,
  tagTypes: ["Audiences"],
  endpoints: (builder) => ({
    /***** --- Get Audience By Id Query --- *****/
    getAudienceById: builder.query<Audience, { audienceId: string }>({
      query: ({ audienceId }) => ({
        type: "sdk",
        method: sdkApis.audiences.getAudience(audienceId),
      }),
    }),
    /***** --- Add Audience Mutation --- *****/
    addAudience: builder.mutation<Audience, { projectId: string; description: string }>({
      query: ({ projectId, description }) => ({
        type: "fetch",
        url: `/syntheticUsers`,
        method: "POST",
        body: { projectId, description },
      }),
      extraOptions: {
        dataSchema: AudiencesSchema,
      },
    }),
    /***** --- Update Audience Mutation --- *****/
    updateAudience: builder.mutation<
      Audience,
      { projectId: string; audienceId: string; description: string }
    >({
      query: ({ projectId, audienceId, description }) => ({
        type: "fetch",
        url: `/syntheticUsers/${audienceId}`,
        method: "PUT",
        body: {
          projectId,
          description,
        },
      }),
      extraOptions: {
        dataSchema: AudiencesSchema,
      },
    }),
  }),
});

// Create the regular slice
export const audiencesSlice = createSlice({
  name: "audiences",
  initialState,
  reducers: {
    /***** --- Reset Audiences --- *****/
    resetAudiences: () => initialState,
    /***** --- Reset Selected Audiences --- *****/
    resetSelectedAudiences: (state) => {
      state.data.selectedAudiences = [];
    },
    /***** --- Set Project Audiences --- *****/
    setProjectAudiences: (
      state,
      action: PayloadAction<{ audiences?: Audience[]; error?: TransportFailure }>
    ) => {
      state.error = action.payload.error;
      state.data.audiences = action.payload.audiences;
      state.data.selectedAudiences = [];
    },
    /***** --- Set Study Audiences --- *****/
    setStudyAudiences: (state, action: PayloadAction<{ audiences?: Audience[] }>) => {
      state.data.selectedAudiences = action.payload.audiences;
    },
  },
  extraReducers: (builder) => {
    builder
      /***** --- Handle Loading --- *****/
      .addMatcher(
        isAnyOf(
          audiencesApi.endpoints.getAudienceById.matchPending,
          audiencesApi.endpoints.addAudience.matchPending,
          audiencesApi.endpoints.updateAudience.matchPending
        ),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          audiencesApi.endpoints.addAudience.matchFulfilled,
          audiencesApi.endpoints.addAudience.matchRejected,
          audiencesApi.endpoints.updateAudience.matchFulfilled,
          audiencesApi.endpoints.updateAudience.matchRejected,
          audiencesApi.endpoints.getAudienceById.matchFulfilled,
          audiencesApi.endpoints.getAudienceById.matchRejected
        ),
        (state) => {
          state.loading = false;
        }
      )
      /***** --- Handle Get Audience By Id Optimistic Update --- *****/
      .addMatcher(audiencesApi.endpoints.getAudienceById.matchFulfilled, (state, action) => {
        state.data.audiences = [action.payload, ...(state.data.audiences || [])];
        state.data.selectedAudiences = [action.payload];
      })
      /***** --- Handle Add Audience Optimistic Update --- *****/
      .addMatcher(audiencesApi.endpoints.addAudience.matchPending, (state, action) => {
        const { requestId } = action.meta;
        // create a temporary audience with the request id as the id
        const tempAudience = {
          id: requestId,
          title: action.meta.arg.originalArgs.description,
          description: action.meta.arg.originalArgs.description,
          createdAt: new Date().toISOString(),
        };
        // add the temporary audience to the audiences state
        state.data.audiences = [tempAudience, ...(state.data.audiences || [])];
        // add the temporary audience to the selectedAudiences state
        state.data.selectedAudiences = [tempAudience];
      })
      // handle server response and replace optimistic update with server data on addAudience
      .addMatcher(audiencesApi.endpoints.addAudience.matchFulfilled, (state, action) => {
        const { requestId } = action.meta;
        const createdAudience = action.payload;
        // find the temporary audience in the audiences state using the request id
        const tempAudienceIndex =
          state.data.audiences?.findIndex((audience) => audience.id === requestId) ?? -1;
        // if the temporary audience is found
        if (tempAudienceIndex !== -1 && state.data.audiences?.[tempAudienceIndex]) {
          // replace the temporary audience with the actual audience
          state.data.audiences[tempAudienceIndex] = createdAudience;
          // replace the temporary selected audience with the actual audience
          state.data.selectedAudiences = state.data.selectedAudiences?.map((audience) =>
            audience.id === requestId ? createdAudience : audience
          );
        }
      })
      // handle server response and remove temporary audience if error occurs on addAudience
      .addMatcher(audiencesApi.endpoints.addAudience.matchRejected, (state, action) => {
        const { requestId } = action.meta;
        // find the temporary audience in the audiences state using the request id
        const tempAudienceIndex =
          state.data.audiences?.findIndex((audience) => audience.id === requestId) ?? -1;
        // if the temporary audience is found
        if (tempAudienceIndex !== -1 && state.data.audiences?.[tempAudienceIndex]?.id) {
          // remove the temporary audience from the audiences state
          state.data.audiences?.splice(tempAudienceIndex, 1);
          // remove the temporary audience from the selectedAudiences state
          state.data.selectedAudiences = state.data.selectedAudiences?.filter(
            (audience) => audience.id !== requestId
          );
        }
      })
      /***** --- Handle Update Audience Fulfilled --- *****/
      .addMatcher(audiencesApi.endpoints.updateAudience.matchFulfilled, (state, action) => {
        const updatedAudience = action.payload;

        // Update the audiences and selectedAudiences with the server data
        state.data = {
          audiences: state.data.audiences?.map((audience) =>
            audience.id === updatedAudience.id ? updatedAudience : audience
          ),
          selectedAudiences: state.data.selectedAudiences?.map((audience) =>
            audience.id === updatedAudience.id ? updatedAudience : audience
          ),
        };
      })
      /***** --- Handle Errors --- *****/
      .addMatcher(
        isAnyOf(
          audiencesApi.endpoints.addAudience.matchRejected,
          audiencesApi.endpoints.updateAudience.matchRejected,
          audiencesApi.endpoints.getAudienceById.matchRejected
        ),
        (state, action) => {
          state.error = parseError(action.error);
        }
      );
  },
});

// Export actions
export const { setProjectAudiences, setStudyAudiences, resetAudiences, resetSelectedAudiences } =
  audiencesSlice.actions;

// export hooks
export const { useAddAudienceMutation, useUpdateAudienceMutation, useGetAudienceByIdQuery } =
  audiencesApi;

// Combine the reducers
export const audiencesReducer = {
  [audiencesApi.reducerPath]: audiencesApi.reducer,
  audiences: audiencesSlice.reducer,
};
