import { z } from "zod";

export const RagFileSchema = z.object({
  id: z.string(),
  filename: z.string(),
  projectId: z.string(),
  uploadedBy: z.string(),
  uploadedAt: z.string(),
  status: z.string(),
  processingPercentage: z.number().optional(),
});

export const UploadRagFileSchema = RagFileSchema.extend({
  presigned: z.object({
    url: z.string(),
    fields: z.record(z.string(), z.string()),
  }),
});

export const RagFilesStatsSchema = z.record(
  z.string(),
  z.object({
    total: z.number(),
    current: z.number(),
  })
);
