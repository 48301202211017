// Redux Toolkit
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Types
import { CustomScript } from "./types";
// Initial state
import { initialState } from "./initial-state";

// Create the regular slice
const customScriptSlice = createSlice({
  name: "custom-script",
  initialState,
  reducers: {
    /***** --- Reset Custom Script --- *****/
    resetCustomScript: () => initialState,
    /***** --- Add Custom Script --- *****/
    addCustomScript: {
      reducer: (state, action: PayloadAction<CustomScript>) => {
        state.data = action.payload;
      },
      prepare: (customScript: string) => {
        return {
          payload: {
            customScript,
            questionsNumber: customScript?.split("\n").filter((line) => line.length).length,
          },
        };
      },
    },
  },
});

// Export Custom Script Actions
export const { resetCustomScript, addCustomScript } = customScriptSlice.actions;

// Export Custom Script Reducer
export const customScriptReducer = {
  customScript: customScriptSlice.reducer,
};
