// Redux Toolkit
import { createApi } from "@reduxjs/toolkit/query/react";
import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
// Store utils
import { customBaseQuery } from "store/utils/custom-base-query";
import { parseError } from "store/utils/parse-error";
// Types
import { SummaryConversation, SummaryConversationMessage } from "./types";
// Schemas
import { SummaryConversationSchema } from "./schemas";
// Initial state
import { initialState } from "./initial-state";
// WebSocket config
import { WebSocketConnectionConfigKeys } from "store/middleware/websockets/types";
// Zod
import { z } from "zod";

// Create the API slice
export const summaryConversationsApi = createApi({
  reducerPath: "summaryConversationsApi",
  baseQuery: customBaseQuery,
  endpoints: (builder) => ({
    /***** --- Get Summary Conversations Query --- *****/
    getSummaryConversations: builder.query<
      SummaryConversation[],
      {
        summaryId: string;
      }
    >({
      query: ({ summaryId }) => ({
        type: "fetch",
        url: `/summaries/${summaryId}/followUp`,
        method: "GET",
      }),
      extraOptions: {
        dataSchema: z.array(SummaryConversationSchema),
        skipAuthentication: true,
      },
    }),
  }),
});

// Create the regular slice
const summaryConversationsSlice = createSlice({
  name: "summaryConversations",
  initialState,
  reducers: {
    /***** --- Reset Summary Conversations --- *****/
    resetSummaryConversations: () => initialState,
    /***** --- Handle WebSocket Connection --- *****/
    connectSummaryConversationsWS: {
      prepare: (summaryId: string, followUpQuestion: string, path?: string) => ({
        payload: {
          key: `${WebSocketConnectionConfigKeys.SummaryConversation}-${summaryId}`,
          resourceId: summaryId,
          queryParams: `message=${followUpQuestion}`,
          path,
        },
      }),
      reducer: (state) => state,
    },
    /***** --- Handle WebSocket Disconnection --- *****/
    disconnectSummaryConversationsWS: {
      prepare: (summaryId: string) => ({
        payload: {
          key: `${WebSocketConnectionConfigKeys.SummaryConversation}-${summaryId}`,
          resourceId: summaryId,
        },
      }),
      reducer: (state) => state,
    },
    /***** --- Handle Streaming Summary Conversations --- *****/
    streamSummaryConversations: (
      state,
      action: PayloadAction<{ clientSideId: string; message: SummaryConversationMessage }>
    ) => {
      const { clientSideId, message } = action.payload;

      const prevConversation = state?.data?.find(
        (conversation) => conversation.id === clientSideId
      );

      const newConversation: SummaryConversation = {
        id: clientSideId,
        message: prevConversation
          ? `${prevConversation.message}${message.message}`
          : message.message,
        created_at: new Date(),
        type: "llm",
      };

      state.data = prevConversation
        ? state.data?.map((conversation) =>
            conversation.id === clientSideId
              ? {
                  ...conversation,
                  message: `${conversation.message}${message.message}`,
                }
              : conversation
          )
        : [...(state.data ? state.data : []), newConversation];
    },
    /***** --- Handle Adding Summary Conversation --- *****/
    addSummaryConversation: (
      state,
      action: PayloadAction<{ summaryId: string; message: string }>
    ) => {
      const { message } = action.payload;

      const newQuestion: SummaryConversation = {
        id: "tempId",
        message,
        created_at: new Date(),
        type: "llm",
      };

      state.data = [...(state.data ? state.data : []), newQuestion];
    },
  },
  extraReducers: (builder) => {
    builder
      /***** --- Handle Loading --- *****/
      .addMatcher(
        isAnyOf(
          summaryConversationsApi.endpoints.getSummaryConversations.matchPending,
          connectSummaryConversationsWS
        ),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          summaryConversationsApi.endpoints.getSummaryConversations.matchFulfilled,
          summaryConversationsApi.endpoints.getSummaryConversations.matchRejected,
          disconnectSummaryConversationsWS
        ),
        (state) => {
          state.loading = false;
        }
      )
      /***** --- Handle Fetch Summary Conversations Fulfilled --- *****/
      .addMatcher(
        summaryConversationsApi.endpoints.getSummaryConversations.matchFulfilled,
        (state, action) => {
          state.data = action.payload;
        }
      )
      /***** --- Handle Fetch Summary Conversations Rejected --- *****/
      .addMatcher(
        isAnyOf(summaryConversationsApi.endpoints.getSummaryConversations.matchRejected),
        (state, action) => {
          state.error = parseError(action.error);
        }
      );
  },
});

// Export actions
export const {
  connectSummaryConversationsWS,
  disconnectSummaryConversationsWS,
  streamSummaryConversations,
  resetSummaryConversations,
  addSummaryConversation,
} = summaryConversationsSlice.actions;

// Export hooks
export const { useGetSummaryConversationsQuery } = summaryConversationsApi;

// Combine the reducers
export const summaryConversationsReducer = {
  [summaryConversationsApi.reducerPath]: summaryConversationsApi.reducer,
  summaryConversations: summaryConversationsSlice.reducer,
};
