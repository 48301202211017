import { z } from "zod";
import { AudiencesSchema } from "../audiences/schemas";
import { ProblemsSchema } from "../problems/schemas";
import { SolutionSchema } from "../solutions/schemas";
import { ResearchGoalSchema } from "../research-goal/schemas";
import { DoneUserInterviewSchema } from "../user-interviews/schemas";
import { SummarySchema } from "../summaries/schemas";
import { GeneratedSyntheticUserSchema } from "../synthetic-users/schemas";
import { RagFileSchema } from "../rag/schemas";

export const StudySchema = z.object({
  id: z.string(),
  description: z.string().optional().nullable(),
  worspaceId: z.string().optional().nullable(),
  createdBy: z.string().optional().nullable(),
  createdAt: z.string(),
  bookmarked: z.boolean(),
  deleted: z.boolean(),
  audiences: z.array(AudiencesSchema),
  problems: z.array(ProblemsSchema),
  solution: SolutionSchema.optional().nullable(),
  researchGoal: ResearchGoalSchema.optional().nullable(),
  studyStrategy: z.string(),
  userInterviews: z.array(DoneUserInterviewSchema),
  summaries: z.array(SummarySchema).optional().nullable(),
  generatedSyntheticUsers: z.array(GeneratedSyntheticUserSchema).optional().nullable(),
  uploadedFiles: z.array(RagFileSchema).optional().nullable(),
  planId: z.string().optional().nullable(),
  status: z.string().optional().nullable(),
});
