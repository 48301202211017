import { TransportFailure } from "logic/internals/transports/transported-data/transport-failures";
import { z } from "zod";
import {
  CompletedStudySubPlanSchema,
  GeneratingStudySubPlanSchema,
  StudyPlanSchema,
  StudySubPlanSchema,
  SuggestionSchema,
} from "./schemas";

export type StudyPlan = z.infer<typeof StudyPlanSchema>;
export type StudySubPlan = z.infer<typeof StudySubPlanSchema>;
export type Suggestion = z.infer<typeof SuggestionSchema>;
export type CompletedStudySubPlan = z.infer<typeof CompletedStudySubPlanSchema>;
export type GeneratingStudySubPlan = z.infer<typeof GeneratingStudySubPlanSchema>;

export enum StudySubPlanStatus {
  Generating = "generating",
  Ready = "ready",
}

export enum SuggestionStatus {
  Created = "created",
  Executing = "executing",
  Done = "done",
}

export enum SubPlanReportStatus {
  Created = "created",
  Generating = "generating",
  Done = "done",
}

export type StateProps = {
  loading: boolean;
  error?: TransportFailure;
  /*
    the idea of loaded data is represented by the truthy value of data,
    hence the need for a string signaling that past interviews were indeed requested
    but there were no past interviews
  */
  data: {
    studyPlan?: StudyPlan;
    studyPlansList?: StudyPlan[];
  };
};
