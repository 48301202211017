// KEEP AS TOP IMPORTS
import "../logic/internals/logging/logger";
import "../logic/internals/runtime/environment-variables";

/* --- */
import "../globals.css";

import "antd/dist/reset.css";

import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
/* --- */

import { config, dom } from "@fortawesome/fontawesome-svg-core";
import { Brawler, Inter } from "next/font/google";
import { ConfigProvider, theme, App as AntApp } from "antd";
import { useContrastingColors } from "components/ui-kit/core/contrasting-colors";
import { useExtraToken } from "components/ui-kit/core/extra-token";
import { AppProps } from "next/app";
import Head from "next/head";
import { ReactNode } from "react";
import { Provider } from "react-redux";
import { store } from "store";
import tinycolor from "tinycolor2";
import { AppAnalytics } from "../components/routing/app/app-analytics";
import { EmailVerificationGate } from "../components/routing/app/email-verification-gate";
import { UncaughtErrorHandler } from "../components/routing/app/uncaught-error-handler";
import { EnvironmentVariables } from "../logic/internals/runtime/environment-variables";
import { AuthContextProvider } from "@/logic/auth-context/provider";

config.autoAddCss = false;

const font1 = Inter({
  weight: ["400", "500", "700"],

  style: ["normal"],
  subsets: ["latin"],
});
const font2 = Brawler({
  weight: ["400", "700"],

  style: ["normal"],
  subsets: ["latin"],
});

function StyleOverrides(props: { children: ReactNode }) {
  const { token } = theme.useToken();
  const extraToken = useExtraToken();
  const contrastingColors = useContrastingColors();

  const css = `
  .ant-btn:disabled {
    color: ${token.colorTextTertiary};
  }

  .ant-card .ant-card-head {
    background-color: ${extraToken.colorPrimaryBgSecondary};
  }

  .ant-modal .ant-modal-content,
  .ant-modal .ant-modal-header {
    background-color: ${token.colorBgBase};
  }
  .ant-modal .ant-modal-body {
    padding-top: ${token.paddingLG}px;
    padding-bottom: ${token.paddingLG}px;
  }

  /*
  .ant-segmented {
    background-color: transparent;
  }


  .ant-segmented .ant-segmented-item .ant-segmented-item-label {
    color: ${token.colorText};
  }

  .ant-segmented .ant-segmented-item {
    background-color: ${token.colorBgBase};
    margin-left: ${token.marginXXS}px;
    margin-right: ${token.marginXXS}px;
    border: 1px solid ${token.colorBorder};
    color: ${token.colorTextTertiary};
  }

  .ant-segmented .ant-segmented-item-selected {
    background-color: ${token.colorPrimary};
    box-shadow: none;
    border: 1px solid ${tinycolor(token.colorPrimary).setAlpha(0.5).toRgbString()};
  }

  
  .ant-segmented .ant-segmented-item-selected .ant-segmented-item-label {
    color: ${contrastingColors.get(token.colorPrimary).neutral};
  }

  .ant-segmented .ant-segmented-thumb {
    background-color: ${token.colorPrimary};
  } 
  */

  .ant-radio-button-wrapper .ant-input {
    font-size: inherit;
  }

  .ant-radio-button-selected {
    background-color: ${token.colorPrimary};
  }

  .ant-form-item-explain-error {
    margin-bottom: ${token.marginXS}px;
  }

  .ant-modal-root .ant-modal-mask {
    z-index: 1071;
  }

  .ant-modal-root .ant-modal-wrap {
    z-index: 1071;
  }
  
  h6,
  h6.ant-typography,
  h5,
  h5.ant-typography,
  h4,
  h4.ant-typography,
  h3,
  h3.ant-typography,
  h2,
  h2.ant-typography,
  h1,
  h1.ant-typography,
  .ant-card-head-title,
  .ant-modal-title {
    font-family: var(--font-1);
  }

  h6,
  h6.ant-typography {
    font-weight: 400;
  }

  h5,
  h5.ant-typography {
    font-weight: 400;
  }

  h4,
  h4.ant-typography {
    font-weight: bold;
  }

  h3,
  h3.ant-typography {

  }


  h2,
  h2.ant-typography {
    font-weight: bold;    
  }

  h1,
  h1.ant-typography {
    font-weight: bold;
  }

  .ant-card {
    border-color: ${token.colorBorder};
  }
  .ant-card .ant-card-head-title {
    overflow: initial;
  }
  .ant-card:not(.ant-card-bordered) {
    background-color: ${token.colorFillSecondary};
  }


  .ant-btn.ant-btn-default {
    background-color: ${tinycolor(token.colorBgBase).toRgbString()};
  }
  
  .ant-btn.ant-btn-text {
    color: ${token.colorPrimaryText};
  }
`;

  return (
    <>
      <style dangerouslySetInnerHTML={{ __html: css }} />
      {props.children}
    </>
  );
}

// Posthog init
if (typeof window !== "undefined") {
  posthog.init(EnvironmentVariables.POSTHOG_KEY, {
    api_host: EnvironmentVariables.POSTHOG_HOST || "https://app.posthog.com",
    loaded: (posthog) => {
      if (process.env.NODE_ENV === "development") posthog.debug(); // debug mode in development
    },
  });
}

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <style>{dom.css()}</style>
      </Head>
      <style jsx global>{`
        html {
          --font-1: ${font1.style.fontFamily};
          --font-2: ${font2.style.fontFamily};
          font-family: ${font1.style.fontFamily};
        }
      `}</style>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#555690",
            colorPrimaryBg: tinycolor("#F6F6FF").darken(2).toRgbString(),
            colorInfo: "#1890FF",
            colorWarning: "#FAAD14",
            colorWarningText: tinycolor("#FAAD14").darken(20).toRgbString(),
            colorSuccess: "#52C41A",
            colorError: "#FF4D4F",
            colorText: "#000000",
            colorLink: "#000000",
            colorLinkHover: "#1890FF",
            colorLinkActive: "#1890FF",
            linkDecoration: "underline",
            linkHoverDecoration: "underline",
            linkFocusDecoration: "underline",
            boxShadow:
              "0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1), 0 2px 2px 0 rgba(0, 0, 0, 0.1)",
            boxShadowSecondary:
              "0 6px 16px 0 rgba(0, 0, 0, 0.1), 0 3px 6px -4px rgba(0, 0, 0, 0.1), 0 9px 28px 8px rgba(0, 0, 0, 0.1)",
            fontFamily: "var(--font-1)",
          },
          components: {
            Drawer: {
              colorBgElevated: "#ffffff",
            },
            Breadcrumb: {
              itemColor: "#000000",
              lastItemColor: "#000000",
              separatorColor: "#000000",
              linkHoverColor: "#000000",
            },
          },
        }}
      >
        <UncaughtErrorHandler>
          <StyleOverrides>
            <PostHogProvider client={posthog}>
              <Provider store={store}>
                <AuthContextProvider>
                  <AntApp>
                    <AppAnalytics>
                      <EmailVerificationGate>
                        <Component {...pageProps} />
                      </EmailVerificationGate>
                    </AppAnalytics>
                  </AntApp>
                </AuthContextProvider>
              </Provider>
            </PostHogProvider>
          </StyleOverrides>
        </UncaughtErrorHandler>
      </ConfigProvider>
    </>
  );
}
