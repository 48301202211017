import { LegacyPlanDetails, StripePlan } from "../types";
import { stripePriceIds } from "./constants";

const basicPlansPriceIds = [
  ...stripePriceIds.testBasic20,
  ...stripePriceIds.testBasic100,
  ...stripePriceIds.prodBasic20,
  ...stripePriceIds.prodBasic100,
];

const businessPlansPriceIds = [
  ...stripePriceIds.testBusiness500,
  ...stripePriceIds.testBusiness1000,
  ...stripePriceIds.prodBusiness500,
  ...stripePriceIds.prodBusiness1000,
];

const isBasicPlan = (subscription: string) => {
  return basicPlansPriceIds.includes(subscription);
};

const isBusinessPlan = (subscription: string) => {
  return businessPlansPriceIds.includes(subscription);
};

const isLegacySubscription = (subscription?: string): "basic" | "business" | false => {
  if (!subscription) return false;
  return isBasicPlan(subscription) ? "basic" : isBusinessPlan(subscription) ? "business" : false;
};

/**
 * Handle legacy plans
 * @param stripePlans - The plans from Stripe
 * @param currentSubscription - The current subscription
 * @returns The plans
 */
export const handleLegacyPlans = (
  stripePlans: StripePlan[],
  currentSubscription?: string
): LegacyPlanDetails | undefined => {
  // if the current subscription is not found or is not a legacy subscription, return undefined
  if (!currentSubscription || !isLegacySubscription(currentSubscription)) return;

  // find the plan that has the price of the current subscription
  const currentPlan = stripePlans.find((plan) =>
    plan.prices.some((price) => price.id === currentSubscription)
  );

  // get the price of the current plan
  const currentPlanPrice = currentPlan?.prices.find((price) => price.id === currentSubscription);

  // if the current plan or the price of the current plan is not found, return undefined
  if (!currentPlan || !currentPlanPrice) return;

  // compose the plan details
  const planDetails: LegacyPlanDetails = {
    type: currentPlan.metadata.plan_type || "",
    title: currentPlan.name,
    description:
      currentPlan.metadata.plan_type === "basic"
        ? "Research superpower for single practitioners"
        : "Collaboration workspaces for research teams",
    price_options: {
      stripe_id: currentPlanPrice.id,
      seats_limit: parseInt(currentPlan.metadata.seats_limit || "0"),
      custom_script_max_questions: parseInt(
        currentPlan.metadata.custom_script_max_questions || "0"
      ),
      price: currentPlanPrice.unit_amount ? currentPlanPrice.unit_amount / 100 : 0,
      saving: 0,
    },
    period: currentPlanPrice?.recurring?.interval,
    included_features:
      "features" in currentPlan && Array.isArray(currentPlan.features)
        ? currentPlan.features?.map((feature: { name: string }) => feature.name) || []
        : [],
  };

  return planDetails;
};
