// Redux Toolkit
import { createApi } from "@reduxjs/toolkit/query/react";
// Store utils
import { customBaseQuery } from "store/utils/custom-base-query";
// Types
import { RedeemSubscriptionResponse } from "./types";
// Schemas
import { RedeemSubscriptionResponseSchema } from "./schema";
// Store modules
import { workspacesApi } from "store/modules/workspaces/slice";

// Create the API slice
export const subscriptionApi = createApi({
  reducerPath: "subscriptionApi",
  baseQuery: customBaseQuery,
  tagTypes: ["Subscription"],
  endpoints: (builder) => ({
    /***** --- Redeem Subscription Mutation --- *****/
    redeemSubscription: builder.mutation<
      RedeemSubscriptionResponse,
      { workspaceId: string; token: string }
    >({
      query: ({ workspaceId, token }) => ({
        type: "fetch",
        url: `/subscription/redeem`,
        method: "POST",
        body: { workspace_id: workspaceId, token },
      }),
      async onQueryStarted({ workspaceId }, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          workspacesApi.endpoints.getWorkspaceById.initiate({ workspaceId }, { forceRefetch: true })
        );
      },
      extraOptions: {
        dataSchema: RedeemSubscriptionResponseSchema,
      },
    }),
  }),
});

// export hooks
export const { useRedeemSubscriptionMutation } = subscriptionApi;

// Combine the reducers
export const subscriptionReducer = {
  [subscriptionApi.reducerPath]: subscriptionApi.reducer,
};
