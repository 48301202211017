import { StudyStrategyCodes } from "./get-study-strategy-code";

/**
 * Workaround until message testing mode is implemented
 * @param studyStrategy
 * @returns studyStrategy
 */
export const handleStudyType = (studyType?: StudyStrategyCodes) => {
  if (studyType === StudyStrategyCodes.MessageTestingMode)
    return StudyStrategyCodes.SolutionFeedbackMode;
  return studyType;
};
