import { initializeApp, FirebaseApp } from "@firebase/app";
import { EnvironmentVariables } from "../../runtime/environment-variables";

let app: FirebaseApp | undefined = undefined;

export const getFirebaseApp = () => {
  if (!app) {
    app = initializeApp({
      apiKey: EnvironmentVariables.FIREBASE_API_KEY,

      authDomain: EnvironmentVariables.FIREBASE_AUTH_DOMAIN,

      projectId: EnvironmentVariables.FIREBASE_PROJECT_ID,

      storageBucket: EnvironmentVariables.FIREBASE_STORAGE_BUCKET,

      messagingSenderId: EnvironmentVariables.FIREBASE_MESSAGING_SENDER_ID,

      appId: EnvironmentVariables.FIREBASE_APP_ID,
    });
  }

  return app;
};
