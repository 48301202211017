import { z } from "zod";
import { GeneratedSyntheticUserSchema } from "../synthetic-users/schemas";

export const PersonalityDetailsSchema = z.object({
  personalInformation: z.record(z.string()).optional().nullable(),
  personalityTraits: z.record(z.string()).optional().nullable(),
  miscellaneous: z.record(z.string()).optional().nullable(),
});

export const InterviewByTopicSchema = z.object({
  message: z.string(),
  topic: z.string(),
});

export const InterviewByTypeSchema = z.object({
  message: z.string(),
  type: z.string(),
  conversation_id: z.string(),
});

export const InterviewAnnotationSchema = z.object({
  id: z.string(),
  conversationId: z.string(),
  userInterviewId: z.string(),
  annotation: z.string(),
  startIndex: z.number(),
  endIndex: z.number(),
  createdAt: z.string(),
  label: z.string().optional().nullable(),
});

const BaseUserInterviewSchema = z.object({
  userInterviewId: z.string(),
  projectId: z.string(),
  syntheticUserId: z.string(),
  studyId: z.string().nullable().optional(),
  createdAt: z.string(),
  helpful: z.boolean().nullable().optional(),
  generatedSyntheticUserDescription: z.string().nullable().optional(),
  generatedSyntheticUserPersonalityDetails: GeneratedSyntheticUserSchema.nullable().optional(),
});

export const UnprocessedUserInterviewSchema = BaseUserInterviewSchema.extend({
  status: z.union([z.literal("unprocessed"), z.literal("preparing_synthetic_users_done")]),
});

export const ProcessingUserInterviewSchema = BaseUserInterviewSchema.extend({
  status: z.literal("processing"),
});

export const FailedUserInterviewSchema = BaseUserInterviewSchema.extend({
  status: z.literal("failed"),
});

export const DoneUserInterviewSchema = BaseUserInterviewSchema.extend({
  status: z.literal("done"),
  userInterview: z.union([z.array(z.string()), z.string()]),
  userInterviewWithTopics: z.array(InterviewByTopicSchema).nullable().optional(),
  userInterviewWithTypes: z.array(InterviewByTypeSchema).nullable().optional(),
  topics: z.array(z.string()).nullable().optional(),
  processedAt: z.string(),
  annotations: z.array(InterviewAnnotationSchema).nullable().optional(),
});

export const UserInterviewInAnyPhaseSchema = z.union([
  UnprocessedUserInterviewSchema,
  ProcessingUserInterviewSchema,
  FailedUserInterviewSchema,
  DoneUserInterviewSchema,
]);

export const UserInterviewWithSyntheticUserSchema = z.object({
  syntheticUserId: z.string().optional(),
  userInterviews: z.array(UserInterviewInAnyPhaseSchema),
});

export const userInterviewMessageSchema = z.object({
  message: z.union([z.string(), GeneratedSyntheticUserSchema]),
  type: z.string(),
  section: z.string().nullable().optional(),
});
