import { UnknownAction } from "@reduxjs/toolkit";
import { WebSocketConnectionConfig } from "store/middleware/websockets/types";
import {
  streamSummaryConversations,
  disconnectSummaryConversationsWS,
  summaryConversationsApi,
} from "./slice";
import { SummaryConversationMessageSchema } from "./schemas";

export const onMessage: WebSocketConnectionConfig<
  typeof SummaryConversationMessageSchema
>["onMessage"] = (message, store, resourceId) => {
  if (resourceId) {
    store.dispatch(streamSummaryConversations({ clientSideId: resourceId, message }));
  }
};

export const onClose: WebSocketConnectionConfig<
  typeof SummaryConversationMessageSchema
>["onClose"] = (store, resourceId, wasClosed) => {
  if (wasClosed && wasClosed.by === "error" && store && resourceId) {
    store.dispatch(disconnectSummaryConversationsWS(resourceId));
  }

  if (wasClosed && wasClosed.by === "server" && store && resourceId) {
    // run fetch for user interview conversations
    store.dispatch(
      summaryConversationsApi.endpoints.getSummaryConversations.initiate({
        summaryId: resourceId,
      }) as unknown as UnknownAction
    ); // Update type of dispatch

    store.dispatch(disconnectSummaryConversationsWS(resourceId));
  }
};

export const onInvalidMessageReceived: WebSocketConnectionConfig<
  typeof SummaryConversationMessageSchema
>["onInvalidMessageReceived"] = (store, resourceId) => {
  if (resourceId) {
    store.dispatch(disconnectSummaryConversationsWS(resourceId));
  }
};

export const summariesConversationsWSConnectionConfig: WebSocketConnectionConfig<
  typeof SummaryConversationMessageSchema
> = {
  path: "/summaries/followUp/stream/",
  messageSchema: SummaryConversationMessageSchema,
  onMessage: onMessage,
  onClose: onClose,
  onInvalidMessageReceived: onInvalidMessageReceived,
};
