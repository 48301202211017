import { SummaryMessage } from "../types";

export const processSummaryMessage = (
  message: SummaryMessage,
  prevSummary: string[],
  currentSectionIndex: number
) => {
  let summedSummaryText = [...prevSummary];
  let summedCurrentSection = prevSummary[currentSectionIndex] || "";

  if (typeof message.message === "string") {
    // handle the beginning of a question
    if (message.type === "beginning_of_question" && message.section === "human") {
      summedCurrentSection = "";

      // handle the end of a question
    } else if (message.type === "end_of_question" && message.section === "human") {
      summedCurrentSection = "";
      summedSummaryText = [...summedSummaryText, summedCurrentSection];
    } else {
      // handle the stream of a section
      if (message.section === "llm" && message.type === "stream") {
        summedCurrentSection = `${summedCurrentSection}${message.message}`;
      }

      // update the last section in the array
      summedSummaryText[currentSectionIndex] = summedCurrentSection;
    }
  }

  return { summedSummaryText };
};
