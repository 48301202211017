import { z } from "zod";

export const SummaryConversationSchema = z.object({
  id: z.string().optional(),
  type: z.union([z.literal("human"), z.literal("llm")]),
  message: z.string(),
  created_at: z.date({ coerce: true }),
});

export const SummaryConversationMessageSchema = z.object({
  message: z.string(),
});
