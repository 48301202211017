// Redux Toolkit
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// Types
import { SsoProvider } from "./types";
// Environment variables
import { EnvironmentVariables } from "@/logic/internals/runtime/environment-variables";

// Create the API slice
export const ssoProviderApi = createApi({
  reducerPath: "ssoProviderApi",
  baseQuery: fetchBaseQuery({ baseUrl: EnvironmentVariables.MAIN_API_URL }),
  tagTypes: ["SsoProvider"],
  endpoints: (builder) => ({
    /***** --- Get Sso Provider Query --- *****/
    getSsoProvider: builder.query<SsoProvider | undefined | null, { email: string }>({
      query: ({ email }) => `/ssoProvider/${email}`,
    }),
  }),
});

// export hooks
export const { useLazyGetSsoProviderQuery } = ssoProviderApi;

// Combine the reducers
export const ssoProviderReducer = {
  [ssoProviderApi.reducerPath]: ssoProviderApi.reducer,
};
