import { UnknownAction } from "@reduxjs/toolkit";
import { WebSocketConnectionConfig } from "store/middleware/websockets/types";
import {
  streamUserInterviewConversations,
  disconnectUserInterviewConversationsWS,
  userInterviewConversationsApi,
} from "./slice";
import { UserInterviewConversationMessageSchema } from "./schemas";

export const onMessage: WebSocketConnectionConfig<
  typeof UserInterviewConversationMessageSchema
>["onMessage"] = (message, store, resourceId) => {
  if (resourceId) {
    store.dispatch(streamUserInterviewConversations({ clientSideId: resourceId, message }));
  }
};

export const onClose: WebSocketConnectionConfig<
  typeof UserInterviewConversationMessageSchema
>["onClose"] = (store, resourceId, wasClosed) => {
  if (wasClosed && wasClosed.by === "error" && store && resourceId) {
    store.dispatch(disconnectUserInterviewConversationsWS(resourceId));
  }

  if (wasClosed && wasClosed.by === "server" && store && resourceId) {
    // run fetch for user interview conversations
    // TODO: check typings
    store.dispatch(
      userInterviewConversationsApi.endpoints.getUserInterviewConversations.initiate(
        {
          interviewId: resourceId,
        },
        { forceRefetch: true }
      ) as unknown as UnknownAction
    ); // Update type of dispatch

    store.dispatch(disconnectUserInterviewConversationsWS(resourceId));
  }
};

export const onInvalidMessageReceived: WebSocketConnectionConfig<
  typeof UserInterviewConversationMessageSchema
>["onInvalidMessageReceived"] = (store, resourceId) => {
  if (resourceId) {
    store.dispatch(disconnectUserInterviewConversationsWS(resourceId));
  }
};

export const userInterviewConversationsWSConnectionConfig: WebSocketConnectionConfig<
  typeof UserInterviewConversationMessageSchema
> = {
  path: "/conversations/stream/",
  messageSchema: UserInterviewConversationMessageSchema,
  onMessage: onMessage,
  onClose: onClose,
  onInvalidMessageReceived: onInvalidMessageReceived,
};
