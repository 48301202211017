import { z } from "zod";

export const UserInterviewConversationSchema = z.object({
  id: z.string(),
  userInterviewId: z.string(),
  type: z.union([z.literal("human"), z.literal("llm")]),
  message: z.string(),
  createdAt: z.date({ coerce: true }),
});

export const UserInterviewConversationMessageSchema = z.object({
  message: z.string(),
});
