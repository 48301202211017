import { streamUserInterviews } from "../slice";

import { store } from "@/store";
import { UserInterviewMessage } from "../types";
import { BATCH_SIZE } from "../slice";

type BatchedMessage = {
  id: string;
  message: UserInterviewMessage;
};

let batchedMessages: BatchedMessage[] = [];

export const handleUserInterviewMessage = (
  userInterviewId: string,
  message: UserInterviewMessage
) => {
  const newMessage = {
    id: userInterviewId,
    message,
  };
  const currMessages = [...batchedMessages, newMessage];

  const currentIdMessage = currMessages.filter((m) => m.id === userInterviewId);

  // Check if we have reached the batch size
  if (currentIdMessage.length >= BATCH_SIZE) {
    const messages = currentIdMessage.map((m) => m.message);
    // Dispatch the batched messages

    store.dispatch(streamUserInterviews({ userInterviewId, messages }));

    // Clear the batched messages array
    batchedMessages = currMessages.filter((m) => m.id !== userInterviewId);
  } else {
    batchedMessages = currMessages;
  }
};
