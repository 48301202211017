import type { FetchArgs, FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { EnvironmentVariables } from "logic/internals/runtime/environment-variables";
import { Logger } from "logic/internals/logging/logger";
import { TransportFailure } from "logic/internals/transports/transported-data/transport-failures";
import { TBaseQuery } from "./types";
import { baseFetchQuery } from "./base-fetch-query";

/**
 * A custom base query implementation that supports both SDK and REST API calls,
 * with built-in data validation using zod schemas, Firebase authentication,
 * and comprehensive error logging.
 *
 * For SDK calls, it executes methods directly on SDK instances.
 * For REST calls, it uses a configured fetchBaseQuery with authentication.
 *
 * @example
 * // Import necessary dependencies
 * import { createApi } from '@reduxjs/toolkit/query/react';
 * import { z } from 'zod';
 * import { customBaseQuery } from './custom-base-query';
 *
 * // Define a schema for the expected response
 * const userSchema = z.object({
 *   id: z.number(),
 *   name: z.string(),
 *   email: z.string().email()
 * });
 *
 * // Define the User type using z.infer
 * type User = z.infer<typeof userSchema>;
 *
 * // Create an API with customBaseQuery
 * export const api = createApi({
 *   baseQuery: customBaseQuery,
 *   endpoints: (builder) => ({
 *     // SDK endpoint example
 *     getUser: builder.query<User, number>({
 *       query: (id) => ({
 *         type: 'sdk',
 *         api: 'userApi',
 *         method: 'getUser',
 *         args: [id]
 *       }),
 *       extraOptions: {
 *         dataSchema: userSchema
 *       }
 *     }),
 *     // REST endpoint example
 *     updateUser: builder.mutation<User, {id: number, data: Partial<User>}>({
 *       query: ({id, data}) => ({
 *         type: 'fetch',
 *         url: `users/${id}`,
 *         method: 'PATCH',
 *         body: data
 *       }),
 *       extraOptions: {
 *         dataSchema: userSchema
 *       }
 *     })
 *   })
 * });
 *
 * // In your component:
 * const { data, error, isLoading } = api.useGetUserQuery(1);
 * // The data will be validated against userSchema,
 * // and Firebase auth token will be automatically injected for REST calls
 */
export const customBaseQuery: TBaseQuery = async (args, api, extraOptions) => {
  // Handle SDK requests
  if (args.type === "sdk") {
    try {
      const data = await args.method;

      if (extraOptions?.dataSchema) {
        const validationResult = extraOptions.dataSchema.safeParse(data);
        if (!validationResult.success) {
          Logger.logError(
            `customBaseQuery:sdk:validation-error on ${api.endpoint}`,
            validationResult.error
          );
          return {
            error: {
              status: "CUSTOM_ERROR",
              error: TransportFailure.UnexpectedResponse,
            },
          };
        }
      }

      return { data };
    } catch (error) {
      Logger.logError(`customBaseQuery:sdk:${api.endpoint}`, error);
      return {
        error: {
          status: "CUSTOM_ERROR",
          error: TransportFailure.UnexpectedResponse,
        },
      };
    }
  }

  // Handle REST requests
  // Step 1: Enhance the query arguments with the authentication token (if available) and base URL
  const enhancedArgs: FetchArgs =
    typeof args === "string"
      ? {
          url: `${EnvironmentVariables.MAIN_API_URL}${args}`,
        }
      : {
          ...args,
          url: `${EnvironmentVariables.MAIN_API_URL}${args.url}${
            args.params ? `?${new URLSearchParams(args.params).toString()}` : ""
          }`,
        };

  try {
    // Step 2: Call the original baseQuery function with the enhanced arguments
    const returnValue = await baseFetchQuery(enhancedArgs, api, extraOptions);

    // Step 3: Retrieve the Zod schema from extraOptions for data validation
    const zodSchema = extraOptions?.dataSchema;
    const { data } = returnValue;

    // Step 4: Validate the response data if both data and schema are present
    if (data && zodSchema) {
      try {
        // Attempt to parse the data with the Zod schema
        const validationResult = zodSchema.safeParse(data);
        if (!validationResult.success) {
          // If validation fails, log the error and return an unexpected response error
          Logger.logError(
            `customBaseQuery:fetch:validation-error for ${enhancedArgs.url}`,
            validationResult.error,
            {
              data,
            }
          );
          return {
            error: { status: "CUSTOM_ERROR", error: TransportFailure.UnexpectedResponse },
          };
        }
      } catch (error) {
        // If validation fails, log the error and return an unexpected response error
        Logger.logError(
          `customBaseQuery:fetch:validation-error for ${enhancedArgs.url}`,
          error instanceof Error ? error : new Error(String(error)),
          {
            data,
          }
        );
        return { error: { status: "CUSTOM_ERROR", error: TransportFailure.UnexpectedResponse } };
      }
    }

    // Step 5: If all checks pass, return the original return value
    return returnValue;
  } catch (error) {
    // Step 6: Handle any errors that occur during the query execution

    // Check if it's a connection failure (TypeError)
    if (error instanceof Error && error.name === "TypeError") {
      // Log the connection failure with request details
      Logger.logError("customBaseQuery:fetch:connection-failure", error, {
        request: {
          path: enhancedArgs.url,
          method: enhancedArgs.method,
          body: enhancedArgs.body as Record<string, unknown>,
        },
      });
      return { error: { status: "FETCH_ERROR", error: TransportFailure.ConnectionFailure } };
    }

    // For any other type of error, treat it as an unexpected response
    Logger.logError(
      "customBaseQuery:fetch:unexpected-response",
      error instanceof Error ? error : new Error(String(error)),
      {
        request: {
          path: enhancedArgs.url,
          method: enhancedArgs.method,
          body: enhancedArgs.body as Record<string, unknown>,
        },
        response: {
          status: (error as FetchBaseQueryError)?.status,
        },
      }
    );
    return { error: { status: "CUSTOM_ERROR", error: TransportFailure.UnexpectedResponse } };
  }
};
