import { z } from "zod";
import { ProjectSchema } from "../projects/schemas";

const SubscriptionUsageStatsSchema = z.object({
  plan: z.string(),
  seatsUsage: z.number(),
  seatsLimit: z.number(),
  interviewsUsage: z.number(),
  interviewsLimit: z.number(),
  customScriptMaxQuestions: z.number(),
  stripeCustomerId: z.string(),
  stripeSubscriptionId: z.string(),
  stripeSubscriptionStatus: z.string(),
  subscriptionEndDate: z.string(),
  extraStudies: z.number(),
});

export const WorkspaceUsageDashboardSchema = z.object({
  subscription_plan_name: z.string(),
  subscription_interviews_limit: z.number(),
  dashboard: z.array(
    z.object({
      timeframe_start: z.string(),
      timeframe_end: z.string(),
      total_interviews: z.number(),
      by_project: z.array(
        z.object({
          name: z.string(),
          total_interviews: z.number(),
          by_user: z.array(
            z.object({
              email: z.string(),
              total_interviews: z.number(),
            })
          ),
        })
      ),
    })
  ),
});

export const WorkspaceSchema = z.object({
  id: z.string(),
  ownerId: z.string(),
  ownerFirebaseId: z.string(),
  owners: z.array(z.string()),
  ownersEmails: z.array(z.string()),
  name: z.string(),
  description: z.string(),
  projects: z.array(ProjectSchema),
  currentSubscriptionStripePriceId: z.union([z.string(), z.null()]),
  activeSubscription: z.boolean(),
  trialSubscription: z.boolean(),
  subscriptionEndDate: z.union([z.string(), z.null()]),
  subscriptionId: z.union([z.string(), z.null()]),
  subscriptionUsageStats: z.union([SubscriptionUsageStatsSchema, z.null()]),
  subscriptionEmail: z.union([z.string(), z.null()]),
});

export const WorkspaceMemberSchema = z.object({
  id: z.union([z.string(), z.null()]),
  email: z.string(),
  role: z.string(),
  status: z.string(),
  workspaceInviteId: z.union([z.string(), z.null()]),
});

export const WorkspaceInviteResponseSchema = z.object({
  success: z.boolean(),
  message: z.string(),
});

export const WorkspaceListItemSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string(),
  status: z.string(),
  active_subscription: z.boolean(),
  workspace_invite_id: z.union([z.string(), z.null()]),
  members: z.union([z.string(), z.null()]),
  plan: z.union([z.string(), z.null()]),
  interviews_left_this_month: z.union([z.number(), z.null()]),
});
