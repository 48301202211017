import { z } from "zod";

export const CurrentUserSchema = z.object({
  id: z.string(),
  firebaseId: z.string(),
  email: z.union([z.string(), z.null()]),
  apiKey: z.union([z.string(), z.null()]),
  lastVisitedProject: z.union([z.string(), z.null()]),
  lastVisitedWorkspace: z.union([z.string(), z.null()]),
});
