import { store } from "../..";
import { resetWorkspace, workspacesApi } from "@/store/modules/workspaces/slice";
import { resetAudiences, audiencesApi } from "../../modules/audiences/slice";
import { resetCustomScript } from "../../modules/custom-script/slice";
import { resetHistory, historyApi } from "../../modules/history/slice";
import { knowledgeGraphApi } from "../../modules/knowledge-graph/slice";
import { resetProblems, problemsApi } from "../../modules/problems/slice";
import { resetRag, ragApi } from "../../modules/rag/slice";
import {
  resetResearchAssistant,
  researchAssistantApi,
} from "../../modules/research-assistant/slice";
import { resetResearchGoal, researchGoalApi } from "../../modules/research-goal/slice";
import { resetSolutions, solutionsApi } from "../../modules/solutions/slice";
import { resetStudy, studyApi } from "../../modules/study/slice";
import {
  resetSummaryConversations,
  summaryConversationsApi,
} from "../../modules/summaries-conversations/slice";
import { resetSummary, summaryApi } from "../../modules/summaries/slice";
import { resetSurveys } from "../../modules/surveys/actions";
import { resetSyntheticUsers, syntheticUsersApi } from "../../modules/synthetic-users/slice";
import {
  resetUserInterviewConversations,
  userInterviewConversationsApi,
} from "../../modules/user-interview-conversations/slice";
import { resetUserInterviews, userInterviewsApi } from "../../modules/user-interviews/slice";
import { resetProject, projectsApi } from "@/store/modules/projects/slice";
import { plansApi } from "@/store/modules/plans/slice";
import { ssoProviderApi } from "@/store/modules/sso-provider/slice";
import { subscriptionApi } from "@/store/modules/subscription/slice";
import { resetCurrentUser, currentUserApi } from "@/store/modules/users/slice";

export const resetAllData = () => {
  store.dispatch(resetAudiences());
  store.dispatch(audiencesApi.util.resetApiState());
  store.dispatch(resetProblems());
  store.dispatch(problemsApi.util.resetApiState());
  store.dispatch(resetSolutions());
  store.dispatch(solutionsApi.util.resetApiState());
  store.dispatch(resetResearchAssistant());
  store.dispatch(researchAssistantApi.util.resetApiState());
  store.dispatch(resetCustomScript());
  store.dispatch(resetResearchGoal());
  store.dispatch(researchGoalApi.util.resetApiState());
  store.dispatch(resetStudy());
  store.dispatch(studyApi.util.resetApiState());
  store.dispatch(resetSyntheticUsers());
  store.dispatch(syntheticUsersApi.util.resetApiState());
  store.dispatch(resetUserInterviews());
  store.dispatch(userInterviewsApi.util.resetApiState());
  store.dispatch(resetUserInterviewConversations());
  store.dispatch(userInterviewConversationsApi.util.resetApiState());
  store.dispatch(resetSummary());
  store.dispatch(summaryApi.util.resetApiState());
  store.dispatch(resetSummaryConversations());
  store.dispatch(summaryConversationsApi.util.resetApiState());
  store.dispatch(knowledgeGraphApi.util.resetApiState());
  store.dispatch(plansApi.util.resetApiState());
  store.dispatch(ssoProviderApi.util.resetApiState());
  store.dispatch(subscriptionApi.util.resetApiState());
  store.dispatch(resetHistory());
  store.dispatch(historyApi.util.resetApiState());
  store.dispatch(resetRag());
  store.dispatch(ragApi.util.resetApiState());
  store.dispatch(resetSurveys());
  store.dispatch(resetProject());
  store.dispatch(projectsApi.util.resetApiState());
  store.dispatch(resetWorkspace());
  store.dispatch(workspacesApi.util.resetApiState());
  store.dispatch(resetCurrentUser());
  store.dispatch(currentUserApi.util.resetApiState());
};
