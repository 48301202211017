import { z } from "zod";
import { AudiencesSchema } from "../audiences/schemas";
import { ProblemsSchema } from "../problems/schemas";
import { ResearchGoalSchema } from "../research-goal/schemas";
import { SolutionSchema } from "../solutions/schemas";
import { UserInterviewInAnyPhaseSchema } from "../user-interviews/schemas";

export const SummarySchema = z.object({
  summary: z.union([z.string(), z.array(z.string())]),
  summaryId: z.string(),
  messages: z.array(z.object({ title: z.string(), content: z.string() })).optional(),
  studyId: z.string().nullable(),
  projectId: z.string(),
  createdAt: z.string(),
  userInterviews: z.array(UserInterviewInAnyPhaseSchema),
  saved: z.boolean(),
  summarizationFocus: z.string().nullable(),
});

const BaseSummarySchema = z.object({
  status: z.string(),
  summaryId: z.string(),
  studyId: z.string().nullable(),
  projectId: z.string(),
  createdAt: z.string(),
  userInterviews: z.array(UserInterviewInAnyPhaseSchema),
  saved: z.boolean(),
  summarizationFocus: z.string().nullable(),
});

export const UnprocessedSummarySchema = BaseSummarySchema.extend({
  status: z.literal("unprocessed"),
});

export const ProcessingSummarySchema = BaseSummarySchema.extend({
  status: z.literal("processing"),
});

export const FailedSummarySchema = BaseSummarySchema.extend({
  status: z.literal("failed"),
});

export const DoneSummarySchema = BaseSummarySchema.extend({
  status: z.literal("done"),
});

export const SummaryInAnyPhaseSchema = z.union([
  UnprocessedSummarySchema,
  ProcessingSummarySchema,
  FailedSummarySchema,
  DoneSummarySchema,
]);

export const SummariesHistoryEntrySchema = z.object({
  summary: z.string(),
  summaryId: z.string(),
  projectId: z.string(),
  createdAt: z.string(),
  userInterviews: z.array(UserInterviewInAnyPhaseSchema),
  syntheticUsers: z.array(AudiencesSchema),
  problems: z.array(ProblemsSchema),
  solution: SolutionSchema.nullable(),
  researchGoal: ResearchGoalSchema.nullable(),
  saved: z.boolean(),
  summarizationFocus: z.string().nullable(),
});

export const SavedSummarySchema: z.ZodType<{ id: string; description: string }> = z.object({
  id: z.string(),
  description: z.string(),
});

export const SummaryMessageSchema = z.object({
  message: z.string(),
  type: z.string(),
  section: z.string().nullable().optional(),
});
