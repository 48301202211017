export enum StudyStrategyCodes {
  ProblemExplorationMode = "problem_exploration",
  SolutionFeedbackMode = "solution_feedback",
  // TODO: update when implemented
  MessageTestingMode = "message_testing",
  CustomScriptMode = "custom_script",
  DynamicInterviewMode = "dynamic_interview",
  EthnographicResearchMode = "ethnographic_research",
}

/**
 * Maps a string to a StudyStrategyCodes enum value.
 *
 * @param key - The string to map.
 * @returns The corresponding StudyStrategyCodes enum value.
 */
export function getStudyStrategyCode(key: string): StudyStrategyCodes {
  switch (key) {
    case "Problem Exploration":
      return StudyStrategyCodes.ProblemExplorationMode;
    case "Dynamic Interview":
      return StudyStrategyCodes.DynamicInterviewMode;
    case "Ethnographic Research":
      return StudyStrategyCodes.EthnographicResearchMode;
    case "Custom Script":
      return StudyStrategyCodes.CustomScriptMode;
    case "Solution Feedback":
      return StudyStrategyCodes.SolutionFeedbackMode;
    default:
      return StudyStrategyCodes.ProblemExplorationMode;
  }
}
