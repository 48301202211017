import { z } from "zod";
import { RagFileSchema } from "../rag/schemas";

export const HistorySchema = z.object({
  id: z.string(),
  description: z.string().optional().nullable(),
  createdAt: z.string(),
  createdBy: z.string().optional().nullable(),
  audiences: z.array(z.string()),
  problems: z.array(z.string()),
  solution: z.string().optional().nullable(),
  researchGoal: z.string().optional().nullable(),
  studyStrategy: z.string(),
  bookmarked: z.boolean(),
  numberOfInterviews: z.number(),
  uploadedFiles: z.array(RagFileSchema),
  status: z.string(),
  planId: z.string().optional().nullable(),
});
