import { z } from "zod";

export const ReleaseNoteSchema = z.object({
  id: z.string(),
  label: z.string(),
  url: z.string(),
  banner: z.boolean(),
  deleted: z.boolean(),
  created_at: z.string(),
});
