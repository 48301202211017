import { useLocalStorage } from "logic/internals/storage/use-local-storage";
import { useCallback, useMemo } from "react";
import { z } from "zod";

const ONGOING_WALKTHROUGH_KEY = "workspace-page:ongoing-walkthrough";

export function useOngoingWalkthrough() {
  const localStorage = useLocalStorage();

  const getOngoingWalkthrough = useCallback(() => {
    return localStorage.getItem(z.boolean({ coerce: true }), ONGOING_WALKTHROUGH_KEY) ?? true;
  }, [localStorage]);

  const markWalkthroughAsDone = useCallback(() => {
    localStorage.setItem(ONGOING_WALKTHROUGH_KEY, false);
  }, [localStorage]);

  return useMemo(
    () => ({
      getOngoingWalkthrough,
      markWalkthroughAsDone,
    }),
    [getOngoingWalkthrough, markWalkthroughAsDone]
  );
}
