import { ReactNode, useEffect, useState } from "react";
import { AuthSession, AuthContext } from "./index";
import { useAppDispatch } from "@/store/hooks";
import { getFirebaseAuth } from "../internals/apis/firebase/firebase-auth";
import { useLocalStorage } from "../internals/storage/use-local-storage";
import { useOngoingWalkthrough } from "../hooks/ongoing-walkthrough";
import { useSessionStorage } from "../internals/storage/use-session-storage";
import { Logger } from "../internals/logging/logger";
import { TransportFailure } from "../internals/transports/transported-data/transport-failures";
import { useResearchAssistantTour } from "../hooks/research-assistant-tour";
import { resetAllData } from "@/store/utils/side-effects/reset-all-data";

type AuthContextProviderProps = {
  children: ReactNode;
};

export const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const dispatch = useAppDispatch();
  const localStorage = useLocalStorage();
  const sessionStorage = useSessionStorage();

  const { getOngoingWalkthrough, markWalkthroughAsDone } = useOngoingWalkthrough();
  const { getDidTour, markTourAsDone } = useResearchAssistantTour();

  const [session, setSession] = useState<AuthSession>({
    user: undefined,
    error: undefined,
    loading: true,
  });

  useEffect(() => {
    const firebaseAuth = getFirebaseAuth();
    const listener = firebaseAuth.onAuthStateChanged(
      (firebaseUser) => {
        // User logged in
        if (firebaseUser) {
          if (session.user?.uid !== firebaseUser.uid) {
            setSession((prev) => ({ ...prev, user: firebaseUser, loading: false }));
          }
        } else {
          const ongoingWalkthrough = getOngoingWalkthrough();
          const didTour = getDidTour();

          // on logout, we want to clear all local storage and session storage
          localStorage.wipeAll();
          sessionStorage.wipeAll();

          // except for the ongoing walkthrough and the research assistant tour
          if (ongoingWalkthrough === false) markWalkthroughAsDone();
          if (didTour === true) markTourAsDone();

          setSession((prev) => ({ ...prev, user: undefined, loading: false }));
          setTimeout(() => {
            resetAllData();
          }, 50);
        }
      },
      (error: unknown) => {
        Logger.logError("FrameWithState:onAuthStateChanged:error", error);
        setSession((prev) => ({
          ...prev,
          error: TransportFailure.UnexpectedResponse,
          loading: false,
        }));
      }
    );

    return () => {
      // Cleanup
      if (listener) {
        listener();
      }
    };
  }, [
    dispatch,
    getOngoingWalkthrough,
    markWalkthroughAsDone,
    getDidTour,
    markTourAsDone,
    localStorage,
    sessionStorage,
    session.user?.uid,
  ]);

  return <AuthContext.Provider value={{ session, setSession }}>{children}</AuthContext.Provider>;
};
