import { z } from "zod";
import { AudiencesSchema } from "../audiences/schemas";
import { ProblemsSchema } from "../problems/schemas";
import { SolutionSchema } from "../solutions/schemas";
import { SavedSummarySchema } from "../summaries/schemas";

const SubscriptionUsageStatsSchema = z.object({
  plan: z.string(),
  seatsUsage: z.number(),
  seatsLimit: z.number(),
  interviewsUsage: z.number(),
  interviewsLimit: z.number(),
  customScriptMaxQuestions: z.number(),
  stripeCustomerId: z.string(),
  stripeSubscriptionId: z.string(),
  stripeSubscriptionStatus: z.string(),
  subscriptionEndDate: z.string(),
});

export const ProjectSchema = z.object({
  id: z.string(),
  ownerId: z.string(),
  ownerFirebaseId: z.string(),
  admins: z.array(z.string()),
  name: z.string(),
  description: z.string(),
  createdAt: z.string(),
  createdBy: z.string().optional().nullable(),
  numberOfStudies: z.number(),
  teamMembersSize: z.number(),
  // TODO: Ask backend to change name to audiences or remove
  syntheticUsers: z.array(AudiencesSchema),
  problems: z.array(ProblemsSchema),
  solutions: z.array(SolutionSchema),
  savedSummaries: z.array(SavedSummarySchema),
  currentSubscriptionStripePriceId: z.union([z.string(), z.null()]),
  activeSubscription: z.boolean(),
  trialSubscription: z.boolean(),
  subscriptionEndDate: z.union([z.string(), z.null()]),
  subscriptionId: z.union([z.string(), z.null()]),
  subscriptionUsageStats: z.union([SubscriptionUsageStatsSchema, z.null()]),
  subscriptionEmail: z.union([z.string(), z.null()]),
});

export const ProjectMemberSchema = z.object({
  id: z.union([z.string(), z.null()]),
  email: z.string(),
  role: z.string(),
  status: z.string(),
  projectInviteId: z.union([z.string(), z.null()]),
});

const ProjectListItemSchema = z.object({
  id: z.string(),
  description: z.string(),
  status: z.string(),
  active_subscription: z.boolean(),
  project_invite_id: z.union([z.string(), z.null()]),
});

export const ProjectsListSchema = z.array(ProjectListItemSchema);
