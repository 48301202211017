// Redux Toolkit
import { createApi } from "@reduxjs/toolkit/query/react";
import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
// Store utils
import { RootState } from "store";
import { handleChangeProject } from "store/utils/side-effects/handle-change-project";
import { handleAddProject } from "store/utils/side-effects/handle-add-project";
import { customBaseQuery } from "store/utils/custom-base-query";
import { parseError } from "store/utils/parse-error";
// Types
import {
  Workspace,
  WorkspaceInviteResponse,
  WorkspaceMember,
  WorkspaceMode,
  WorkspacesListItem,
  WorkspaceUsageDashboard,
} from "./types";
// Schemas
import {
  WorkspaceInviteResponseSchema,
  WorkspaceListItemSchema,
  WorkspaceMemberSchema,
  WorkspaceSchema,
  WorkspaceUsageDashboardSchema,
} from "./schemas";
// Initial state
import { initialState } from "./initial-state";
// Store modules
import { plansApi } from "store/modules/plans/slice";
import { setWorkspaceProjects } from "store/modules/projects/slice";
// Zod
import { z } from "zod";

// Create the API slice
export const workspacesApi = createApi({
  reducerPath: "workspacesApi",
  baseQuery: customBaseQuery,
  tagTypes: ["WorkspacesList", "WorkspaceMembers"],
  endpoints: (builder) => ({
    /***** --- Get Workspaces List Query --- *****/
    getWorkspacesList: builder.query<WorkspacesListItem[], void>({
      query: () => ({
        type: "fetch",
        url: "/workspaces/workspaces/list",
      }),
      providesTags: ["WorkspacesList"],
      extraOptions: {
        dataSchema: z.array(WorkspaceListItemSchema),
      },
    }),
    /***** --- Get First Workspace Query --- *****/
    getFirstWorkspace: builder.query<Workspace[], void>({
      query: () => ({
        type: "fetch",
        url: "/workspaces/workspaces",
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled;
        const workspaces = result.data;
        const latestWorkspace = workspaces?.[0];
        const project = latestWorkspace?.projects[0];
        const projectsList = latestWorkspace?.projects;

        if (project && projectsList) {
          dispatch(setWorkspaceProjects({ project, projectsList }));
          handleChangeProject(project);
        }
      },
      extraOptions: {
        dataSchema: z.array(WorkspaceSchema),
      },
    }),
    /***** --- Get Workspace By Id Query --- *****/
    getWorkspaceById: builder.query<
      Workspace,
      { workspaceId: string; projectId?: string; skipGetProjects?: boolean }
    >({
      query: ({ workspaceId }) => ({
        type: "fetch",
        url: `/workspaces/workspaces/${workspaceId}`,
      }),
      async onQueryStarted({ projectId, skipGetProjects }, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled;
        const workspace = result.data;
        const project = projectId
          ? workspace?.projects.find((p) => p.id === projectId) ?? workspace?.projects[0]
          : workspace?.projects[0];
        const projectsList = workspace?.projects;

        // Side effects
        if (project && !skipGetProjects) {
          dispatch(setWorkspaceProjects({ project, projectsList }));
          handleChangeProject(project);
        }
        dispatch(plansApi.util.invalidateTags(["Plans"]));
      },
      extraOptions: {
        dataSchema: WorkspaceSchema,
      },
    }),
    /***** --- Add Workspace Mutation --- *****/
    addWorkspace: builder.mutation<
      Workspace,
      {
        name: string;
        description?: string;
      }
    >({
      query: ({ name, description }) => ({
        type: "fetch",
        url: `/workspaces`,
        method: "POST",
        body: { name, description },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled;
        const workspace = result.data;
        const project = workspace?.projects[0];
        const projectsList = workspace?.projects;

        // Side effects
        if (project) {
          dispatch(setWorkspaceProjects({ project, projectsList }));
          handleAddProject();
        }
        dispatch(plansApi.util.invalidateTags(["Plans"]));
      },
      invalidatesTags: ["WorkspacesList"],
      extraOptions: {
        dataSchema: WorkspaceSchema,
      },
    }),
    /***** --- Update Workspace Description Mutation --- *****/
    updateWorkspaceDescription: builder.mutation<
      Workspace,
      {
        workspaceId: string;
        name: string;
        description?: string;
      }
    >({
      query: ({ workspaceId, name, description }) => ({
        type: "fetch",
        url: `/workspaces/workspaces/${workspaceId}/description`,
        method: "PUT",
        body: { name, description },
      }),
      extraOptions: {
        dataSchema: WorkspaceSchema,
      },
    }),
    /***** --- Delete Workspace Mutation --- *****/
    deleteWorkspace: builder.mutation<
      void,
      {
        workspaceId: string;
      }
    >({
      query: ({ workspaceId }) => ({
        type: "fetch",
        url: `/workspaces/workspaces/${workspaceId}`,
        method: "DELETE",
      }),
      async onQueryStarted({ workspaceId }, { queryFulfilled, dispatch, getState }) {
        await queryFulfilled;

        const workspacesList = (getState() as RootState).workspaces.data.workspacesList;
        const remainingWorkspaces = workspacesList?.filter(
          (workspace) => workspace.id !== workspaceId
        );
        const workspaceToLoad = remainingWorkspaces && remainingWorkspaces[0];

        // Side effects
        if (workspaceToLoad) {
          dispatch(
            workspacesApi.endpoints.getWorkspaceById.initiate(
              { workspaceId: workspaceToLoad.id },
              { forceRefetch: true }
            )
          );
        }
      },
    }),
    /***** --- Get Workspace Usage Dashboard Query --- *****/
    getWorkspaceUsageDashboard: builder.query<
      WorkspaceUsageDashboard | null,
      { workspaceId: string }
    >({
      query: ({ workspaceId }) => ({
        type: "fetch",
        url: `/workspaces/${workspaceId}/usage-dashboard`,
      }),
      extraOptions: {
        dataSchema: WorkspaceUsageDashboardSchema.nullable(),
      },
    }),
    /***** --- Get Workspace Members Query --- *****/
    getWorkspaceMembers: builder.query<WorkspaceMember[], { workspaceId: string }>({
      query: ({ workspaceId }) => ({
        type: "fetch",
        url: `/workspaces/workspaces/${workspaceId}/users`,
      }),
      extraOptions: {
        dataSchema: z.array(WorkspaceMemberSchema),
      },
    }),
    /***** --- Remove Workspace Member Mutation --- *****/
    removeWorkspaceMember: builder.mutation<
      WorkspaceMember[],
      {
        workspaceId: string;
        userId: string;
      }
    >({
      query: ({ workspaceId, userId }) => ({
        type: "fetch",
        url: `/workspaces/workspaces/${workspaceId}/users/${userId}`,
        method: "DELETE",
      }),
      extraOptions: {
        dataSchema: z.array(WorkspaceMemberSchema),
      },
    }),
    /***** --- Grant Workspace Member Owner Status Mutation --- *****/
    grantWorkspaceMemberOwnerStatus: builder.mutation<
      WorkspaceMember[],
      {
        workspaceId: string;
        userId: string;
      }
    >({
      query: ({ workspaceId, userId }) => ({
        type: "fetch",
        url: `/workspaces/workspaces/${workspaceId}/users/${userId}/owner`,
        method: "POST",
      }),
      extraOptions: {
        dataSchema: z.array(WorkspaceMemberSchema),
      },
    }),
    /***** --- Revoke Workspace Member Owner Status Mutation --- *****/
    revokeWorkspaceMemberOwnerStatus: builder.mutation<
      WorkspaceMember[],
      {
        workspaceId: string;
        userId: string;
      }
    >({
      query: ({ workspaceId, userId }) => ({
        type: "fetch",
        url: `/workspaces/workspaces/${workspaceId}/users/${userId}/revoke-owner`,
        method: "POST",
      }),
      extraOptions: {
        dataSchema: z.array(WorkspaceMemberSchema),
      },
    }),
    /***** --- Accept Workspace Invite Mutation --- *****/
    acceptWorkspaceInvite: builder.mutation<
      WorkspaceInviteResponse | { detail: string },
      {
        workspaceInviteId: string;
        workspaceId: string;
      }
    >({
      query: ({ workspaceInviteId, workspaceId }) => ({
        type: "fetch",
        url: `/workspaceInvites/accept/${workspaceInviteId}`,
        method: "POST",
        body: { workspaceId },
      }),
      async onQueryStarted({ workspaceId }, { queryFulfilled, dispatch }) {
        await queryFulfilled;
        dispatch(
          workspacesApi.endpoints.getWorkspaceById.initiate({ workspaceId }, { forceRefetch: true })
        );
      },
      invalidatesTags: ["WorkspacesList"],
      extraOptions: {
        dataSchema: z.union([WorkspaceInviteResponseSchema, z.object({ detail: z.string() })]),
      },
    }),
    /***** --- Reject Workspace Invite Mutation --- *****/
    rejectWorkspaceInvite: builder.mutation<
      WorkspaceInviteResponse | { detail: string },
      {
        workspaceInviteId: string;
      }
    >({
      query: ({ workspaceInviteId }) => ({
        type: "fetch",
        url: `/workspaceInvites/reject/${workspaceInviteId}`,
        method: "POST",
      }),
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        await queryFulfilled;
        dispatch(
          workspacesApi.endpoints.getWorkspacesList.initiate(undefined, { forceRefetch: true })
        );
      },
      extraOptions: {
        dataSchema: z.union([WorkspaceInviteResponseSchema, z.object({ detail: z.string() })]),
      },
    }),
    /***** --- Remove Workspace Invite Mutation --- *****/
    removeWorkspaceInvite: builder.mutation<
      WorkspaceMember[],
      {
        workspaceInviteId: string;
      }
    >({
      query: ({ workspaceInviteId }) => ({
        type: "fetch",
        url: `/workspaceInvites/${workspaceInviteId}`,
        method: "DELETE",
      }),
      extraOptions: {
        dataSchema: z.array(WorkspaceMemberSchema),
      },
    }),
  }),
});

// Create the regular slice
export const workspacesSlice = createSlice({
  name: "workspaces",
  initialState,
  reducers: {
    /***** --- Reset Workspace --- *****/
    resetWorkspace: () => initialState,
    /***** --- Set Workspace Mode --- *****/
    setWorkspaceMode: (state, action: PayloadAction<WorkspaceMode>) => {
      state.data.workspaceMode = action.payload;
    },
    /***** --- Set Workspaces Loading --- *****/
    setWorkspacesLoading: (state) => {
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      /***** --- Handle Loading --- *****/
      .addMatcher(
        isAnyOf(
          workspacesApi.endpoints.getWorkspaceById.matchPending,
          workspacesApi.endpoints.getFirstWorkspace.matchPending,
          workspacesApi.endpoints.getWorkspacesList.matchPending,
          workspacesApi.endpoints.updateWorkspaceDescription.matchPending,
          workspacesApi.endpoints.addWorkspace.matchPending,
          workspacesApi.endpoints.deleteWorkspace.matchPending,
          workspacesApi.endpoints.acceptWorkspaceInvite.matchPending,
          workspacesApi.endpoints.rejectWorkspaceInvite.matchPending,
          workspacesApi.endpoints.removeWorkspaceInvite.matchPending,
          workspacesApi.endpoints.getWorkspaceMembers.matchPending,
          workspacesApi.endpoints.removeWorkspaceMember.matchPending,
          workspacesApi.endpoints.getWorkspaceUsageDashboard.matchPending,
          workspacesApi.endpoints.grantWorkspaceMemberOwnerStatus.matchPending,
          workspacesApi.endpoints.revokeWorkspaceMemberOwnerStatus.matchPending
        ),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          workspacesApi.endpoints.getWorkspaceById.matchFulfilled,
          workspacesApi.endpoints.getWorkspaceById.matchRejected,
          workspacesApi.endpoints.getFirstWorkspace.matchFulfilled,
          workspacesApi.endpoints.getFirstWorkspace.matchRejected,
          workspacesApi.endpoints.getWorkspacesList.matchFulfilled,
          workspacesApi.endpoints.getWorkspacesList.matchRejected,
          workspacesApi.endpoints.updateWorkspaceDescription.matchFulfilled,
          workspacesApi.endpoints.updateWorkspaceDescription.matchRejected,
          workspacesApi.endpoints.addWorkspace.matchFulfilled,
          workspacesApi.endpoints.addWorkspace.matchRejected,
          workspacesApi.endpoints.deleteWorkspace.matchFulfilled,
          workspacesApi.endpoints.deleteWorkspace.matchRejected,
          workspacesApi.endpoints.getWorkspaceUsageDashboard.matchFulfilled,
          workspacesApi.endpoints.getWorkspaceUsageDashboard.matchRejected,
          workspacesApi.endpoints.getWorkspaceMembers.matchFulfilled,
          workspacesApi.endpoints.getWorkspaceMembers.matchRejected,
          workspacesApi.endpoints.removeWorkspaceMember.matchFulfilled,
          workspacesApi.endpoints.removeWorkspaceMember.matchRejected,
          workspacesApi.endpoints.grantWorkspaceMemberOwnerStatus.matchFulfilled,
          workspacesApi.endpoints.grantWorkspaceMemberOwnerStatus.matchRejected,
          workspacesApi.endpoints.revokeWorkspaceMemberOwnerStatus.matchFulfilled,
          workspacesApi.endpoints.revokeWorkspaceMemberOwnerStatus.matchRejected,
          workspacesApi.endpoints.removeWorkspaceInvite.matchFulfilled,
          workspacesApi.endpoints.removeWorkspaceInvite.matchRejected,
          workspacesApi.endpoints.acceptWorkspaceInvite.matchFulfilled,
          workspacesApi.endpoints.acceptWorkspaceInvite.matchRejected,
          workspacesApi.endpoints.rejectWorkspaceInvite.matchFulfilled,
          workspacesApi.endpoints.rejectWorkspaceInvite.matchRejected
        ),
        (state) => {
          state.loading = false;
        }
      )
      /***** --- Get Workspaces List Fulfilled --- *****/
      .addMatcher(workspacesApi.endpoints.getWorkspacesList.matchFulfilled, (state, action) => {
        state.data.workspacesList = action.payload;
      })
      /***** --- Get First Workspace Fulfilled --- *****/
      .addMatcher(workspacesApi.endpoints.getFirstWorkspace.matchFulfilled, (state, action) => {
        state.data.workspace = action.payload[0];
      })
      /***** --- Get Workspace By Id Fulfilled --- *****/
      .addMatcher(workspacesApi.endpoints.getWorkspaceById.matchFulfilled, (state, action) => {
        state.data.workspace = action.payload;
      })
      /***** --- Add Workspace Fulfilled --- *****/
      .addMatcher(workspacesApi.endpoints.addWorkspace.matchFulfilled, (state, action) => {
        const newWorkspace = action.payload;
        state.data.workspace = newWorkspace;
        state.data.workspaceMembers = undefined;
      })
      /***** --- Update Workspace Description Fulfilled --- *****/
      .addMatcher(
        workspacesApi.endpoints.updateWorkspaceDescription.matchFulfilled,
        (state, action) => {
          const updatedWorkspace = action.payload;
          state.data.workspace = updatedWorkspace;
          state.data.workspacesList = state.data.workspacesList?.map((workspace) => ({
            ...workspace,
            name: workspace.id === updatedWorkspace.id ? updatedWorkspace.name : workspace.name,
            description:
              workspace.id === updatedWorkspace.id
                ? updatedWorkspace.description
                : workspace.description,
          }));
        }
      )
      /***** --- Delete Workspace Fulfilled --- *****/
      .addMatcher(workspacesApi.endpoints.deleteWorkspace.matchFulfilled, (state, action) => {
        const deletedWorkspaceId = action.meta?.arg?.originalArgs?.workspaceId;
        state.data.workspace = undefined;
        state.data.workspacesList = state.data.workspacesList?.filter(
          (workspace) => workspace.id !== deletedWorkspaceId
        );
        state.data.workspaceMembers = undefined;
      })
      /***** --- Get Workspace Usage Dashboard Fulfilled --- *****/
      .addMatcher(
        workspacesApi.endpoints.getWorkspaceUsageDashboard.matchFulfilled,
        (state, action) => {
          state.data.workspaceUsageDashboard = action.payload ?? undefined;
        }
      )
      /***** --- Workspace Members Fulfilled --- *****/
      .addMatcher(
        isAnyOf(
          workspacesApi.endpoints.getWorkspaceMembers.matchFulfilled,
          workspacesApi.endpoints.removeWorkspaceMember.matchFulfilled,
          workspacesApi.endpoints.grantWorkspaceMemberOwnerStatus.matchFulfilled,
          workspacesApi.endpoints.revokeWorkspaceMemberOwnerStatus.matchFulfilled,
          workspacesApi.endpoints.removeWorkspaceInvite.matchFulfilled
        ),
        (state, action) => {
          state.data.workspaceMembers = action.payload;
        }
      )
      /***** --- Handle Errors --- *****/
      .addMatcher(
        isAnyOf(
          workspacesApi.endpoints.getWorkspaceById.matchRejected,
          workspacesApi.endpoints.getFirstWorkspace.matchRejected,
          workspacesApi.endpoints.getWorkspacesList.matchRejected,
          workspacesApi.endpoints.updateWorkspaceDescription.matchRejected,
          workspacesApi.endpoints.addWorkspace.matchRejected,
          workspacesApi.endpoints.deleteWorkspace.matchRejected,
          workspacesApi.endpoints.acceptWorkspaceInvite.matchRejected,
          workspacesApi.endpoints.rejectWorkspaceInvite.matchRejected,
          workspacesApi.endpoints.removeWorkspaceInvite.matchRejected,
          workspacesApi.endpoints.getWorkspaceMembers.matchRejected,
          workspacesApi.endpoints.removeWorkspaceMember.matchRejected,
          workspacesApi.endpoints.getWorkspaceUsageDashboard.matchRejected,
          workspacesApi.endpoints.grantWorkspaceMemberOwnerStatus.matchRejected,
          workspacesApi.endpoints.revokeWorkspaceMemberOwnerStatus.matchRejected
        ),
        (state, action) => {
          const error = parseError(action.error);
          state.error = error;
        }
      );
  },
});

// Export actions
export const { setWorkspaceMode, resetWorkspace, setWorkspacesLoading } = workspacesSlice.actions;

// Export hooks
export const {
  useGetWorkspacesListQuery,
  useLazyGetWorkspacesListQuery,
  useGetWorkspaceByIdQuery,
  useLazyGetWorkspaceByIdQuery,
  useAddWorkspaceMutation,
  useUpdateWorkspaceDescriptionMutation,
  useDeleteWorkspaceMutation,
  useGetWorkspaceUsageDashboardQuery,
  useGetWorkspaceMembersQuery,
  useRemoveWorkspaceMemberMutation,
  useGrantWorkspaceMemberOwnerStatusMutation,
  useRevokeWorkspaceMemberOwnerStatusMutation,
  useAcceptWorkspaceInviteMutation,
  useRejectWorkspaceInviteMutation,
  useRemoveWorkspaceInviteMutation,
} = workspacesApi;

// Combine the reducers
export const workspacesReducer = {
  [workspacesApi.reducerPath]: workspacesApi.reducer,
  workspaces: workspacesSlice.reducer,
};
