import { useMemo } from "react";
import { z } from "zod";

class LocalStorage {
  getItem<S extends z.ZodType<unknown>>(schema: S, key: string): z.TypeOf<S> {
    if (typeof window === "undefined") {
      return undefined;
    }
    const data = window.localStorage.getItem(key);

    if (!data) {
      return undefined;
    }

    //const validationResult = schema.parse(data ? JSON.parse(data) : undefined);

    let parsedData: z.TypeOf<S>;
    try {
      parsedData = JSON.parse(data) as z.TypeOf<S>;
    } catch (e) {
      //console.error("Error parsing JSON from localStorage: ", e);
      return undefined;
    }

    const validationResult = schema.parse(parsedData);

    return validationResult;
  }

  setItem(key: string, value: unknown): void {
    if (typeof window === "undefined") {
      return undefined;
    }
    window.localStorage.setItem(key, JSON.stringify(value));
  }

  removeItem(key: string): void {
    if (typeof window === "undefined") {
      return undefined;
    }
    window.localStorage.removeItem(key);
  }

  wipeAll(): void {
    if (typeof window === "undefined") {
      return undefined;
    }
    window.localStorage.clear();
  }
}

export function useLocalStorage() {
  return useMemo(() => new LocalStorage(), []);
}
