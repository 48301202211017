// Redux Toolkit
import { createApi } from "@reduxjs/toolkit/query/react";
import { createSlice, isAnyOf } from "@reduxjs/toolkit";
// Store utils
import { customBaseQuery } from "store/utils/custom-base-query";
import { parseError } from "store/utils/parse-error";
// Types
import { CurrentUser } from "./types";
// Schemas
import { CurrentUserSchema } from "./schemas";
// Initial state
import { initialState } from "./initial-state";

// Create the API slice
export const currentUserApi = createApi({
  reducerPath: "usersApi",
  baseQuery: customBaseQuery,
  tagTypes: ["Users"],
  endpoints: (builder) => ({
    /***** --- Get Current User Query --- *****/
    getCurrentUser: builder.query<CurrentUser, void>({
      query: () => ({
        type: "fetch",
        url: "/users/current",
      }),
      extraOptions: {
        dataSchema: CurrentUserSchema,
      },
    }),
    /***** --- Create User Mutation --- *****/
    createUser: builder.query<CurrentUser, void>({
      query: () => ({
        type: "fetch",
        url: "/users/create",
      }),
      extraOptions: {
        dataSchema: CurrentUserSchema,
      },
    }),
  }),
});

// Create the regular slice
export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    /***** --- Reset Current User --- *****/
    resetCurrentUser: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      /***** --- Handle Loading --- *****/
      .addMatcher(
        isAnyOf(
          currentUserApi.endpoints.getCurrentUser.matchPending,
          currentUserApi.endpoints.createUser.matchPending
        ),
        (state) => {
          state.loading = true;
        }
      )
      /***** --- Handle Fulfilled --- *****/
      .addMatcher(
        isAnyOf(
          currentUserApi.endpoints.getCurrentUser.matchFulfilled,
          currentUserApi.endpoints.createUser.matchFulfilled
        ),
        (state, action) => {
          state.data.currentUser = action.payload;
          state.loading = false;
        }
      )
      /***** --- Handle Rejected --- *****/
      .addMatcher(
        isAnyOf(
          currentUserApi.endpoints.getCurrentUser.matchRejected,
          currentUserApi.endpoints.createUser.matchRejected
        ),
        (state, action) => {
          state.loading = false;
          state.error = parseError(action.error);
        }
      );
  },
});

// Export actions
export const { resetCurrentUser } = usersSlice.actions;

// export hooks
export const { useGetCurrentUserQuery, useLazyGetCurrentUserQuery, useLazyCreateUserQuery } =
  currentUserApi;

// Combine the reducers
export const currentUserReducer = {
  [currentUserApi.reducerPath]: currentUserApi.reducer,
  currentUser: usersSlice.reducer,
};
