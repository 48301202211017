import { useLocalStorage } from "logic/internals/storage/use-local-storage";
import { useCallback, useMemo } from "react";
import { z } from "zod";

const RESEARCH_ASSISTANT_DID_TOUR_KEY = "research-assistant:did-tour";

export function useResearchAssistantTour() {
  const localStorage = useLocalStorage();

  const getDidTour = useCallback(() => {
    return (
      localStorage.getItem(z.boolean({ coerce: true }), RESEARCH_ASSISTANT_DID_TOUR_KEY) ?? false
    );
  }, [localStorage]);

  const markTourAsDone = useCallback(() => {
    localStorage.setItem(RESEARCH_ASSISTANT_DID_TOUR_KEY, true);
  }, [localStorage]);

  return useMemo(
    () => ({
      getDidTour,
      markTourAsDone,
    }),
    [getDidTour, markTourAsDone]
  );
}
