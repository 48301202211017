// Redux Toolkit
import { createApi } from "@reduxjs/toolkit/query/react";
import { createSlice, isAnyOf } from "@reduxjs/toolkit";
// Store utils
import { customBaseQuery } from "store/utils/custom-base-query";
import { parseError } from "store/utils/parse-error";
// Types
import { Study } from "./types";
// Schemas
import { StudySchema } from "./schemas";
// Initial state
import { initialState } from "./initial-state";
// Store modules
import { knowledgeGraphApi } from "store/modules/knowledge-graph/slice";
import { setStudyAudiences } from "store/modules/audiences/slice";
import { setStudySolutions } from "store/modules/solutions/slice";
import { setStudyProblems } from "store/modules/problems/slice";
import { setStudySyntheticUsers } from "store/modules/synthetic-users/slice";
import { resetResearchGoal, setResearchGoal } from "store/modules/research-goal/slice";
import { addCustomScript, resetCustomScript } from "store/modules/custom-script/slice";
import { setStudyUserInterviews } from "store/modules/user-interviews/slice";
import { setSummary, resetSummary } from "store/modules/summaries/slice";
import { updateStudyHistory, deleteStudyHistory } from "store/modules/history/slice";
import { userInterviewConversationsApi } from "../user-interview-conversations/slice";

// Create the API slice
export const studyApi = createApi({
  reducerPath: "studyApi",
  baseQuery: customBaseQuery,
  tagTypes: ["Study"],
  endpoints: (builder) => ({
    /***** --- Get Study Query --- *****/
    getStudy: builder.query<Study, { studyId: string }>({
      query: ({ studyId }) => ({
        type: "fetch",
        url: `/studies/${studyId}`,
      }),
      extraOptions: {
        dataSchema: StudySchema,
      },
    }),
    /***** --- Populate Study Query --- *****/
    populateStudy: builder.query<Study, { studyId: string }>({
      query: ({ studyId }) => ({
        type: "fetch",
        url: `/studies/${studyId}`,
      }),
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        const result = await queryFulfilled;
        const {
          audiences,
          problems,
          solution,
          researchGoal,
          generatedSyntheticUsers: syntheticUsers,
          userInterviews,
          summaries,
          studyStrategy,
        } = result.data;

        const customScript =
          studyStrategy === "Custom Script" ? userInterviews[0]?.topics?.join("\n") : undefined;

        const userInterviewsIds = userInterviews.map(
          (userInterview) => userInterview.userInterviewId
        );

        dispatch(setStudyAudiences({ audiences }));
        dispatch(setStudyProblems({ problems }));
        dispatch(setStudySolutions({ solution }));
        researchGoal ? dispatch(setResearchGoal(researchGoal)) : dispatch(resetResearchGoal());
        customScript ? dispatch(addCustomScript(customScript)) : dispatch(resetCustomScript());
        !!syntheticUsers?.length && dispatch(setStudySyntheticUsers({ syntheticUsers }));
        !!userInterviews.length && dispatch(setStudyUserInterviews({ userInterviews }));
        !!userInterviewsIds.length &&
          userInterviewsIds.forEach((userInterviewId) => {
            dispatch(
              userInterviewConversationsApi.endpoints.getUserInterviewConversations.initiate({
                interviewId: userInterviewId,
              })
            );
          });
        dispatch(summaries?.length ? setSummary({ summary: summaries }) : resetSummary());
        dispatch(knowledgeGraphApi.util.invalidateTags(["KnowledgeGraph"]));
      },
      extraOptions: {
        dataSchema: StudySchema,
        skipAuthentication: true,
      },
    }),
    /***** --- Update Study Description Mutation --- *****/
    updateStudyDescription: builder.mutation<Study, { studyId: string; description: string }>({
      query: ({ studyId, description }) => ({
        type: "fetch",
        url: `/studies/${studyId}/description`,
        method: "PUT",
        body: description,
      }),
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        const result = await queryFulfilled;
        dispatch(
          updateStudyHistory({ id: result.data.id, description: result.data.description ?? "" })
        );
      },
      extraOptions: {
        dataSchema: StudySchema,
      },
    }),
    /***** --- Delete Study Mutation --- *****/
    deleteStudy: builder.mutation<void, { studyId: string }>({
      query: ({ studyId }) => ({
        type: "fetch",
        url: `/studies/${studyId}`,
        method: "DELETE",
      }),
      async onQueryStarted({ studyId }, { queryFulfilled, dispatch }) {
        await queryFulfilled;
        dispatch(deleteStudyHistory({ id: studyId }));
      },
    }),
    /***** --- Bookmark Study Mutation --- *****/
    bookmarkStudy: builder.mutation<Study, { studyId: string }>({
      query: ({ studyId }) => ({
        type: "fetch",
        url: `/studies/${studyId}/bookmark`,
        method: "PUT",
      }),
      extraOptions: {
        dataSchema: StudySchema,
      },
    }),
    /***** --- Unbookmark Study Mutation --- *****/
    unbookmarkStudy: builder.mutation<Study, { studyId: string }>({
      query: ({ studyId }) => ({
        type: "fetch",
        url: `/studies/${studyId}/unbookmark`,
        method: "PUT",
      }),
      extraOptions: {
        dataSchema: StudySchema,
      },
    }),
  }),
});

// Create the regular slice
export const studySlice = createSlice({
  name: "study",
  initialState,
  reducers: {
    /***** --- Reset Study --- *****/
    resetStudy: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      /***** --- Handle Loading --- *****/
      .addMatcher(
        isAnyOf(
          studyApi.endpoints.getStudy.matchPending,
          studyApi.endpoints.populateStudy.matchPending,
          studyApi.endpoints.updateStudyDescription.matchPending,
          studyApi.endpoints.deleteStudy.matchPending
        ),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          studyApi.endpoints.getStudy.matchFulfilled,
          studyApi.endpoints.getStudy.matchRejected,
          studyApi.endpoints.populateStudy.matchFulfilled,
          studyApi.endpoints.populateStudy.matchRejected,
          studyApi.endpoints.updateStudyDescription.matchFulfilled,
          studyApi.endpoints.updateStudyDescription.matchRejected,
          studyApi.endpoints.deleteStudy.matchFulfilled,
          studyApi.endpoints.deleteStudy.matchRejected
        ),
        (state) => {
          state.loading = false;
        }
      )
      /***** --- Handle Fulfilled --- *****/
      .addMatcher(
        isAnyOf(
          studyApi.endpoints.getStudy.matchFulfilled,
          studyApi.endpoints.populateStudy.matchFulfilled,
          studyApi.endpoints.bookmarkStudy.matchFulfilled,
          studyApi.endpoints.unbookmarkStudy.matchFulfilled
        ),
        (state, action) => {
          state.data = action.payload;
        }
      )
      /***** --- Handle Errors --- *****/
      .addMatcher(
        isAnyOf(
          studyApi.endpoints.getStudy.matchRejected,
          studyApi.endpoints.populateStudy.matchRejected,
          studyApi.endpoints.updateStudyDescription.matchRejected,
          studyApi.endpoints.deleteStudy.matchRejected,
          studyApi.endpoints.bookmarkStudy.matchRejected,
          studyApi.endpoints.unbookmarkStudy.matchRejected
        ),
        (state, action) => {
          const error = parseError(action.error);
          state.error = error;
        }
      );
  },
});

// Export actions
export const { resetStudy } = studySlice.actions;

// Export hooks
export const {
  useGetStudyQuery,
  usePopulateStudyQuery,
  useUpdateStudyDescriptionMutation,
  useDeleteStudyMutation,
  useBookmarkStudyMutation,
  useUnbookmarkStudyMutation,
} = studyApi;

// Combine the reducers
export const studyReducer = {
  [studyApi.reducerPath]: studyApi.reducer,
  study: studySlice.reducer,
};
