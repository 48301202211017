// Redux Toolkit
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// Stripe
import Stripe from "stripe";
// Types
import { StateProps, StripePlan } from "./types";
// Utils
import { handleLegacyPlans } from "./utils/handle-legacy-plan";
import { handleNewPlans } from "./utils/hanlde-new-plans";
// Environment variables
import { EnvironmentVariables } from "logic/internals/runtime/environment-variables";

// Create the API slice
export const plansApi = createApi({
  reducerPath: "plansApi",
  baseQuery: fetchBaseQuery(),
  tagTypes: ["Plans"],
  endpoints: (builder) => ({
    /***** --- Get Plans Query --- *****/
    getPlans: builder.query<StateProps["data"], { currentSubscription?: string }>({
      queryFn: async ({ currentSubscription }) => {
        try {
          const stripe = new Stripe(EnvironmentVariables.STRIPE_KEY, {});

          const [prices, products] = await Promise.all([
            stripe.prices.list({ active: true, limit: 100 }),
            stripe.products.list({ active: true, limit: 100 }),
          ]);

          const plans: StripePlan[] = products.data.map((product) => {
            const productPrices = prices.data.filter((price) => price.product === product.id);
            return {
              ...product,
              prices: productPrices,
              default_price:
                typeof product.default_price === "string" ? product.default_price : null,
            } as StripePlan;
          });

          const newPlans = handleNewPlans(plans);
          const legacyPlan = handleLegacyPlans(plans, currentSubscription);

          return {
            data: {
              plans: {
                legacyPlan,
                newPlans,
              },
            },
          };
        } catch (error) {
          return { error: { status: "CUSTOM_ERROR", error: String(error) } };
        }
      },
      providesTags: ["Plans"],
    }),
  }),
});

// Export hooks
export const { useGetPlansQuery } = plansApi;

// Export Reducer
export const plansReducer = {
  plansApi: plansApi.reducer,
};
