import { z } from "zod";
import { RUNNING_IN_SERVER } from "./running-in";

const APP_ENV = process.env.NEXT_PUBLIC_APP_ENV;

const schema = z
  .object({
    APP_ENV: z.union([
      z.literal("local"),
      z.literal("dev"),
      z.literal("staging"),
      z.literal("production"),
    ]),
    PATH_PREFIX: z
      .string()
      .optional()
      .transform((pathPrefix) => pathPrefix || ""),
    DISABLE_LOGGING_LIMIT: z
      .boolean()
      .optional()
      .transform((v) => {
        if (APP_ENV === "local") {
          return true;
        } else {
          return v;
        }
      }),
    LOG_DEBUG: z
      .boolean()
      .optional()
      .transform((v) => {
        if (APP_ENV === "local") {
          return true;
        } else {
          return v;
        }
      }),
    MAIN_API_URL: z.string(),
    MAIN_API_SOCKET_URL: z.string(),
    DEBUG_FEATURES: z
      .boolean({ coerce: true })
      .optional()
      .transform((v) => {
        if (APP_ENV === "local") {
          return true;
        } else {
          return v;
        }
      }),
    SEGMENT_APP_ID: APP_ENV === "production" ? z.string() : z.string().or(z.undefined()),
    DISABLE_ERROR_BOUNDARIES: z
      .string()
      .optional()
      .transform((v) => {
        if (v === "true") {
          return true;
        } else if (v === "false") {
          return false;
        } else {
          if (APP_ENV === "local") {
            return true;
          } else {
            return false;
          }
        }
      }),
    SENTRY_DSN:
      APP_ENV === "production" || APP_ENV === "staging" ? z.string() : z.string().or(z.undefined()),
    SENTRY_ENV:
      APP_ENV === "production" || APP_ENV === "staging"
        ? z.union([z.literal("staging"), z.literal("production")])
        : z.undefined(),
    SENTRY_AUTH_TOKEN:
      (APP_ENV === "production" || APP_ENV === "staging") && RUNNING_IN_SERVER
        ? z.string()
        : z.undefined(),

    FIREBASE_API_KEY: z.string(),
    FIREBASE_AUTH_DOMAIN: z.string(),
    FIREBASE_PROJECT_ID: z.string(),
    FIREBASE_STORAGE_BUCKET: z.string(),
    FIREBASE_MESSAGING_SENDER_ID: z.string(),
    FIREBASE_APP_ID: z.string(),

    CLARITY_ID: z.union([z.string(), z.undefined()]),

    INTERCOM_ID:
      APP_ENV === "production" || APP_ENV === "staging" ? z.string() : z.string().or(z.undefined()),

    MAINTENANCE_MODE: z.string(),
    MULTI_WORKSPACES_MODE: z.string(),
    STRIPE_KEY: z.string(),
    STRIPE_CUSTOMER_PORTAL: z.string(),
    PAYWALL_ACTIVATION_ENABLED: z.string(),
    DYNAMIC_INTERVIEWS_ENABLED: z.string(),
    FORMBRICKS_KEY: z.string(),
    POSTHOG_KEY: z.string(),
    POSTHOG_HOST: z.string(),
  })
  .required();

const environmentVariablesValidationResult = schema.parse({
  APP_ENV: process.env.NEXT_PUBLIC_APP_ENV,
  MAIN_API_URL: process.env.NEXT_PUBLIC_MAIN_API_URL,
  MAIN_API_SOCKET_URL: process.env.NEXT_PUBLIC_MAIN_API_SOCKET_URL,
  DEBUG_FEATURES: process.env.NEXT_PUBLIC_DEBUG_FEATURES,
  DISABLE_ERROR_BOUNDARIES: process.env.NEXT_PUBLIC_DISABLE_ERROR_BOUNDARIES,

  SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  SENTRY_ENV: process.env.NEXT_PUBLIC_SENTRY_ENV,
  SENTRY_AUTH_TOKEN: process.env.SENTRY_AUTH_TOKEN,

  FIREBASE_API_KEY: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  FIREBASE_PROJECT_ID: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,

  SEGMENT_APP_ID: process.env.NEXT_PUBLIC_SEGMENT_APP_ID,
  CLARITY_ID: process.env.NEXT_PUBLIC_CLARITY_ID,

  INTERCOM_ID: process.env.NEXT_PUBLIC_INTERCOM_ID,

  MAINTENANCE_MODE: process.env.NEXT_PUBLIC_MAINTENANCE_MODE,

  MULTI_WORKSPACES_MODE: process.env.NEXT_PUBLIC_MULTI_WORKSPACES_MODE,

  STRIPE_KEY: process.env.NEXT_PUBLIC_STRIPE_KEY,
  STRIPE_CUSTOMER_PORTAL: process.env.NEXT_PUBLIC_STRIPE_CUSTOMER_PORTAL,

  PAYWALL_ACTIVATION_ENABLED: process.env.NEXT_PUBLIC_PAYWALL_ACTIVATION_ENABLED,

  DYNAMIC_INTERVIEWS_ENABLED: process.env.NEXT_PUBLIC_DYNAMIC_INTERVIEWS_ENABLED,
  FORMBRICKS_KEY: process.env.NEXT_PUBLIC_FORMBRICKS_KEY,
  POSTHOG_KEY: process.env.NEXT_PUBLIC_POSTHOG_KEY,
  POSTHOG_HOST: process.env.NEXT_PUBLIC_POSTHOG_HOST,
});

export const EnvironmentVariables = environmentVariablesValidationResult;
