type Obj = Record<string | number, unknown> | Array<unknown>;

function isValidObject(value: unknown): value is Obj {
  return typeof value === "object" && value !== null;
}

function sortObject<T extends Obj>(obj: T): T {
  return Object.fromEntries(
    Object.entries(obj).sort(([a], [b]) => {
      if (String(a) < String(b)) return -1;
      if (String(a) > String(b)) return 1;
      return 0;
    })
  ) as T;
}

/**
 * Compares two values for equality.
 * If both values are objects or arrays, they are sorted and stringified for deep comparison.
 * For primitive values, a strict equality check is performed.
 *
 * @param a - The first value to compare.
 * @param b - The second value to compare.
 * @returns {boolean} True if the values are equal, false otherwise.
 */
export const isEqual = (a: unknown, b: unknown): boolean =>
  isValidObject(a) && isValidObject(b)
    ? JSON.stringify(sortObject(a)) === JSON.stringify(sortObject(b))
    : a === b;
