import { useRouter } from "next/router";
import { ReactNode, useEffect } from "react";
import { getAnalytics } from "../../../logic/analytics/analytics";

export function AppAnalytics(props: { children: ReactNode }) {
  const router = useRouter();
  const analytics = getAnalytics();

  useEffect(() => {
    const listener = (path: string) => {
      analytics.track("navigated-to", { path });
    };

    router.events.on("routeChangeComplete", listener);

    return () => {
      router.events.off("routeChangeComplete", listener);
    };
  }, [analytics, router]);

  return <>{props.children}</>;
}
