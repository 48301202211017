import React, { ReactNode } from "react";
import { Logger } from "../../../logic/internals/logging/logger";
import { EnvironmentVariables } from "../../../logic/internals/runtime/environment-variables";
import { FatalError } from "./fatal-error";

type ErrorBoundaryProps = { children: ReactNode };
type ErrorBoundaryState = { fatalErrorOccurred: boolean };
class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { fatalErrorOccurred: false };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { fatalErrorOccurred: true };
  }

  componentDidCatch(error: unknown, errorInfo: unknown) {
    Logger.logError("root-frame-error-boundary", error, { errorInfo });
  }

  render() {
    return <>{this.state.fatalErrorOccurred ? <FatalError /> : this.props.children}</>;
  }
}

export const UncaughtErrorHandler = (props: { children: ReactNode }) => {
  return (
    <>
      {(() => {
        if (EnvironmentVariables.DISABLE_ERROR_BOUNDARIES) {
          return props.children;
        } else {
          return <ErrorBoundary>{props.children}</ErrorBoundary>;
        }
      })()}
    </>
  );
};
