import { TransportFailure } from "logic/internals/transports/transported-data/transport-failures";

function isCustomError(
  error: unknown
): error is { status: "CUSTOM_ERROR"; error: TransportFailure } {
  return (
    typeof error === "object" &&
    error !== null &&
    "status" in error &&
    error.status === "CUSTOM_ERROR" &&
    "error" in error &&
    Object.values(TransportFailure).includes(error.error as TransportFailure)
  );
}

/**
 * Parses an unknown error and returns a TransportFailure.
 *
 * @param error - The unknown error to parse.
 * @returns A TransportFailure enum value. If the error is a custom error, it returns the specific error type.
 *          Otherwise, it returns TransportFailure.UnexpectedResponse.
 */
export function parseError(error: unknown): TransportFailure {
  if (isCustomError(error)) {
    return error.error;
  }

  return TransportFailure.UnexpectedResponse;
}

/**
 * Type guard to check if an error object has a 'detail' property in its data.
 *
 * @param error - The unknown error to check.
 * @returns A type predicate indicating whether the error is of the shape
 *          { status: number; data: { detail: string } }.
 */
export const errorHasDetail = (
  error: unknown
): error is { status: number; data: { detail: string } } => {
  return (
    typeof error === "object" &&
    error !== null &&
    "status" in error &&
    "data" in error &&
    typeof error.data === "object" &&
    error.data !== null &&
    "detail" in error.data
  );
};
